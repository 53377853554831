import { makeStyles } from "@material-ui/styles";

const styles = (theme) => {
  return {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    btn: {
      textTransform: "none",
      fontWeight: 700,
    },
  };
};
export default makeStyles(styles);
