import {
  ADD_SOURCES,
  ADD_MARKERS,
  SET_KEY_VALUE,
  HANDLE_CLICK_POINT,
  HANDLE_CLICK_SECTION,
  HANDLE_SELECT_COUNTY,
  SET_LOADING,
  SET_DISTRIBUTION_LOADING,
  SET_ANIMATE,
} from "./constants";

let lang = "pt";
if (typeof window !== `undefined`) {
  const storedLang = localStorage.getItem("lang");
  const userLang = storedLang || navigator.language || navigator.userLanguage;
  if (userLang !== "pt" && userLang !== "pt-BR") {
    lang = "en";
  }
}

const initalState = {
  lang,
  landingPage: false, // FIXME only here for testing purposes
  sources: {},
  markers: {},
  countyID: "1106",
  pointSelected: null,
  sectionSelected: null,
  mapConfigs: {
    scrollZoom: true,
    center: [-9.158570127596645, 38.74423962361125 - (window.innerWidth <= 600 ? 0.05 : 0)],
    zoom: window.innerWidth <= 600 ? 11 : 12,
  },
  animate: false,
  loading: true,
  distributionLoading: true,
  baseDistributionRange: {
    min: null,
    mean: null,
    max: null,
  },
  layer: "p", // [n - none , p - price gradient , a - absortion rate ]
};

const reducers = (state = initalState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_DISTRIBUTION_LOADING:
      return {
        ...state,
        distributionLoading: action.distributionLoading,
      };
    case SET_ANIMATE:
      return {
        ...state,
        animate: action.animate,
      };
    case ADD_SOURCES:
      return {
        ...state,
        sources: { ...action.sources },
      };
    case ADD_MARKERS:
      return {
        ...state,
        markers: [...action.markers],
      };
    case HANDLE_CLICK_POINT:
      return {
        ...state,
        pointSelected: action.pointID,
        sectionSelected: action.sectionID,
        idx: action.idx,
      };
    case HANDLE_CLICK_SECTION:
      return {
        ...state,
        pointSelected: action.pointID,
        sectionSelected: action.sectionID,
        idx: action.idx,
      };
    case HANDLE_SELECT_COUNTY:
      const { countyID, center, zoom } = action;
      return {
        ...state,
        landingPage: false,
        countyID,
        mapConfigs: {
          ...state.mapConfigs,
          center,
          zoom,
        },
      };
    case SET_KEY_VALUE:
      const { key, value } = action;
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
};

export default reducers;
