import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setKeyValue } from "../../redux/rootReducer/actions";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ArrowDown from "@material-ui/icons/ArrowBackOutlined";
import Grid from "@material-ui/core/Grid";
import SearchField from "../../components/SearchField";
import { DRAWER } from "../../data/copy";
import styled from "styled-components";

const defaultTheme = createMuiTheme();

const useStyles = makeStyles({
  root: {
    position: "absolute",
  },
  modal: {
    position: "absolute !important",
    zIndex: 1300,
    right: "0px",
    bottom: "0px",
    top: "0px",
    left: "0px",
  },
  tagLine: {
    fontSize: 22,
    lineHeight: 2,
    color: "#636d72",
    padding: "25px 0px",
    [defaultTheme.breakpoints.only("xs")]: {
      lineHeight: 1,
      fontSize: 18,
      width: "100%",
    },
  },
  tagLineTwo: {
    fontSize: 16,
    color: "#636d72",
    padding: "0px",
    [defaultTheme.breakpoints.only("xs")]: {
      fontSize: 12,
    },
  },
  list: {
    width: "575px",
    height: "100%",

    // width: "100%",
    [defaultTheme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  listLeft: {
    background: "#009de0",
    width: "65px",
  },
  listRight: {
    width: "510px",
    [defaultTheme.breakpoints.only("xs")]: {
      width: "calc(100% - 65px)",
    },
  },
  title: {
    fontSize: 48,
    color: "#2e3c43",
    fontWeight: 700,
    [defaultTheme.breakpoints.only("xs")]: {
      fontSize: 28,
    },
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { landingPage, lang } = useSelector((state) => state.rootReducer);

  const copy = DRAWER[lang];

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    dispatch(setKeyValue("landingPage", open));
  };

  const list = () => (
    <Grid container className={classes.list}>
      <Grid item className={classes.listLeft}>
        <Grid container style={{ height: "100%" }} alignItems="flex-end">
          <div
            onClick={() =>
              document.getElementById("landing-page").scrollIntoView({ behavior: "smooth" })
            }
            style={{
              color: "white",
              fontWeight: 600,
              transform: "rotate(-90deg)",
              flexShrink: 0,
              transformOrigin: "bottom",
              marginBottom: 80,
              cursor: "pointer",
              paddingLeft: 0,
            }}
          >
            <IconButton onClick={toggleDrawer("left", true)}>
              <ArrowDownIcon></ArrowDownIcon>
            </IconButton>
            {copy.knowMore}
          </div>
        </Grid>
      </Grid>
      <Grid item xs className={classes.listRight}>
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item xs></Grid>
          <Grid item xs={12}>
            <Grid container style={{ padding: 25 }}>
              <Grid item xs={12} style={{ fontSize: 18, color: "#2e3c43", paddingBottom: 15 }}>
                {copy.welcome}
              </Grid>
              <Grid item xs={12} className={classes.title}>
                {copy.title}
              </Grid>
              <Grid item xs={12} className={classes.tagLine}>
                {copy.tagLine}
              </Grid>
              <Grid item xs={12} className={classes.tagLineTwo}>
                {copy.tagLineTwo}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: 25,
                  color: "#262626",
                }}
              >
                {copy.selectCounty}
              </Grid>
              <Grid item xs style={{ paddingTop: 15 }}>
                <SearchField variant="drawer" />
              </Grid>

              <Grid
                item
                xs={12}
                onClick={toggleDrawer("left", false)}
                style={{
                  paddingTop: 15,
                  textDecoration: "underline",
                  color: "#009de0",
                  cursor: "pointer",
                }}
              >
                {copy.startExplore}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div style={{ paddingLeft: 5 }}>
      <React.Fragment key={"left"}>
        {window.innerWidth < 600 ? (
          <IconButton onClick={toggleDrawer("left", true)} style={{ paddingLeft: 0 }}>
            <InfoIcon style={{ color: "white" }}></InfoIcon>
          </IconButton>
        ) : (
          <Fab
            variant="extended"
            size="small"
            onClick={toggleDrawer("left", true)}
            style={{
              background: "transparent",
              border: "2px solid white",
              color: "white",
              textTransform: "capitalize",
              boxShadow: "none",
            }}
          >
            <InfoIcon style={{ color: "white", marginRight: 5 }}></InfoIcon>
            {copy.information}
          </Fab>
        )}

        <Drawer
          disableEnforceFocus
          disableEscapeKeyDown
          //  disableBackdropClick
          disableScrollLock
          anchor={"left"}
          //  open={state["left"]}
          open={landingPage}
          disableScrollLock
          onClose={toggleDrawer("left", false)}
          classes={{
            root: classes.modal,
            paper: classes.root,
            paperAnchorLeft: classes.root,
          }}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const ArrowDownIcon = styled(ArrowDown)`
  color: white;
`;
