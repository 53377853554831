import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import { Grid, ListItem, ListItemText } from "@material-ui/core";
import { WIDGETS } from "../../data/copy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  text: {
    fontSize: 26,
    color: "#2e3c43",
    padding: "5px 0",
    fontFamily: "open sans",
  },
  description: {
    fontSize: 10,
    fontFamily: "open sans",
    color: "#2e3c43",
    paddingBottom: "15px",
  },
  warning: {
    padding: "0px 0px 10px",
    fontSize: 12,
    color: "#2e3c43",
    display: "flex",
    alignItems: "center",
  },
  container: {
    padding: "8px 0px 15px 0px",
  },
  showingContainer: {
    padding: "0px",
  },
}));

const renderValue = (value, units) => {
  if (!value) return "";
  return `${value.toFixed(0)} ${units || ""}`;
};

const WidgetIndicator = (props) => {
  const classes = useStyles();
  const { data, units, source, section, id, sectionName, loading } = props;
  const { lang } = useSelector((state) => state.rootReducer);
  const copy = WIDGETS[lang];

  return (
    <div>
      <ListItem style={{ padding: "0px 16px" }}>
        {!data && !loading ? (
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <ListItem className={classes.showingContainer}>
                <ListItemText primary={copy.noResults} />
              </ListItem>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} className={classes.text}>
              {renderValue(data, units)}
            </Grid>
            <Grid item xs={12} className={classes.description}>
              {copy[id] && `${copy[id]}: ${sectionName} (${copy[section]})`}
            </Grid>
            {source && (
              <Grid item xs={12} className={classes.description}>
                {copy.source}: {source}
              </Grid>
            )}
            {data && data < 30 && (
              <Grid item xs={12} className={classes.warning}>
                <WarningIcon style={{ color: "#FFC107", paddingRight: 5 }} /> {copy.lowSampling}
              </Grid>
            )}
          </Grid>
        )}
      </ListItem>
    </div>
  );
};

export default WidgetIndicator;
