import { makeStyles } from "@material-ui/styles";

const styles = (theme) => {
  return {
    tooltip: {
      textTransform: "capitalize",
      background: "white",
      borderRadius: 3,
      fontSize: 10,
      padding: 5,
    },
    description: {
      fontSize: 10,
      fontFamily: "open sans",
      color: "#2e3c43",
      paddingTop: "10px",
    },
    formGroup: {
      padding: "12px 16px",
    },
    checkboxes: {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    svg: {
      width: 12,
      height: 12,
    },
    polygon: {
      fill: "rgb(0,157,224)",
    },
    distributionRange: {
      padding: "16px 24px",
      textAlign: "center",
    },
    min: {
      fontWeight: 600,
      fontSize: "16px",
      textAlign: "left",
    },
    mean: {
      color: "#1c1c1c",
      fontSize: "36px",
      fontStyle: "normal",
      textAlign: "center",
      lineHeight: 0.83,
      fontStretch: "normal",
      letterSpacing: "normal",
    },
    max: {
      fontWeight: 600,
      fontSize: "16px",
      textAlign: "right",
    },
    descMax: {
      paddingBottom: "5px",
      fontSize: "12px",
      textAlign: "right",
      color: "rgb(158, 157, 157)",
    },
    descMin: {
      paddingBottom: "5px",
      fontSize: "12px",
      textAlign: "left",
      color: "rgb(158, 157, 157)",
    },
    chipContainer: {
      padding: "16px 24px 0px 24px",
    },
    chipItem: {
      padding: "4px 0px",
    },
    chip: {
      width: "157px",
      fontSize: "11px",
    },
    chipMobile: {
      width: "100%",
      fontSize: "13px",
    },
    container: {
      padding: "8px 16px 15px 16px",
    },
    showingContainer: {
      padding: "0px",
    },
    showingContainerNoRooms: {
      padding: "0px",
    },
    textNoRooms: { fontSize: "18px", textAlign: "center" },
  };
};

export default makeStyles(styles);
