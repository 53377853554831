import React from "react";
import { HANDLE_SELECT_COUNTY, HANDLE_CLICK_SECTION } from "../../redux/rootReducer/constants";
/* import { fade, makeStyles } from "@material-ui/core/styles"; */
import InputAdornment from "@material-ui/core/InputAdornment";
import counties from "../../helpers/fe_counties_new.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import styled from "styled-components";

/* const useStyles = makeStyles((theme) => ({ */
/*   grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    fontSize: 20,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  searchA: {
    position: "relative",
  },
  search: {
    position: "relative",

    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
  
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    background: "#112330",
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    padding: theme.spacing(0.5, 1, 0.5, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  inputInputDrawer: {
    background: "white",
    border: "1px solid #112330",
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    padding: theme.spacing(0.5, 1, 0.5, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    padding: "7.5px 5px",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  input: {
    color: "#262626 !important",
  },
  inputNormal: {
    color: "white !important",
  },
  icon: {
    color: "white",
  }, */
/* })); */

const SearchField = () => {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const countyID = useSelector((state) => state.rootReducer.countyID);

  const options = Object.keys(counties).map((key) => {
    const firstLetter = counties[key].title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...counties[key],
    };
  });

  return (
    <AutocompleteCustom
      id="search-county"
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.title}
      onChange={(e, options) => {
        if (options) {
          const zoom = window.innerWidth <= 600 ? 11.5 : 12;
          const center =
            window.innerWidth <= 600
              ? [options.centroid[0], options.centroid[1] - 0.025]
              : options.centroid;

          dispatch({
            type: HANDLE_SELECT_COUNTY,
            countyID: options.county_id,
            center,
            zoom,
          });
          dispatch({
            type: HANDLE_CLICK_SECTION,
            sectionID: null,
          });
        }
      }}
      value={counties[countyID]}
      noOptionsText="Não encontrado"
      renderInput={(params) => {
        return (
          <div>
            {/*      <div className="search-icon"></div> */}
            <TextField
              {...params}
              className=""
              //className={props.variant === "drawer" ? classes.inputInputDrawer : classes.inputInput}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: "",
                //className: "props.variant === "drawer" ? classes.input : classes.inputNormal",
              }}
              inputProps={{
                ...params.inputProps,
              }}
              placeholder="Procurar concelho"
              /* classes={{
                root: classes.search,
              }} */
            />
          </div>
        ); /* .search-icon {
          padding: 5px;
          height: 100%;
          position: absolute;
          pointer-events: none;
          display: flex;
          align-items: center;
          justify-content: center;
        } */
      }}
    />
  );
};

const AutocompleteCustom = styled(Autocomplete)`
  background: #112330;
  margin-right: 10px;
  border-radius: 3px;
  padding: 2.5px;

  button {
    color: white;
  }

  input {
    color: white;
  }

  MuiInput {
    background: #112330;
  }
`;

const SearchIcon = styled(Search)`
  padding: 12px;
  color: white;
`;

export default SearchField;
