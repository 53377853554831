import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import AlfredoLogo from "../../assets/ALFR_A_NEG_S.PNG";
import AlfredoLogoAlt from "../../assets/ALFR_A_POS_S.png";
import Grid from "@material-ui/core/Grid";
import SearchField from "../SearchField";
import Drawer from "../Drawer";
import AdminDialog from "../AdminDialog";
import LangSwitcher from "../../components/LangSwitcher";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    fontSize: 20,
    textTransform: "uppercase",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  langContainer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <LangSwitcher symbol />
        {/*   <Grid container alignItems="center">
          <Grid item style={{ marginRight: 10, color: "#000" }}>
            Powered by
          </Grid>
          <a
            href="https://www.alfredo.pt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AlfredoLogoAlt} height={30} alt="alfredo-logo"></img>
          </a>
        </Grid> */}
      </MenuItem>
    </Menu>
  );

  return (
    <div
      className={classes.grow}
      style={{
        position: "absolute",
        flexShrink: 0,
        top: 0,
        zIndex: 99,
        right: 0,
        left: 0,
      }}
    >
      <AppBar
        position="static"
        style={{ borderRadius: 5, background: "rgba(1,1,1,0.5)" }}
      >
        <Toolbar>
          <SearchField />
          {/*     <HelperModal />
           */}
          <Drawer />
          <LangSwitcher symbol className={classes.langContainer} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Grid container alignItems="center">
              <Grid item style={{ margin: "0px 10px" }}>
                <AdminDialog />
              </Grid>
              <Grid item style={{ margin: "0px 10px" }}>
                Powered by
              </Grid>
              <a
                href="https://www.alfredo.pt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AlfredoLogo} height={25} alt="alfredo-logo"></img>
              </a>
            </Grid>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon style={{ color: "white" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
