import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import RepPortuguesa from "../../assets/rep_portuguesa.png";
import DGES from "../../assets/dges.png";
import {
  MOTIVATION,
  FEATURES,
  METHODOLOGY,
  SUPPORT,
  ALFREDO,
  PROTOCOLS,
  HOW_WORKS,
} from "../../data/copy";

const useStyles = makeStyles((theme) => ({
  container: {
    //height: "100vh",
    background: "white",
    zIndex: 99999,
  },
  innerContainer: {
    maxWidth: "1400px",
  },
  title: {
    fontSize: 36,
    marginBottom: 12,
  },
  subTitle: {
    fontSize: 16,
    color: "rgb(99, 109, 114)",
    [theme.breakpoints.only("xs")]: {
      padding: "0px 25px",
    },
  },
  sideIcon: {
    minHeight: 100,
    padding: 35,
    [theme.breakpoints.down("md")]: {
      padding: 5,
    },
    [theme.breakpoints.only("xs")]: {
      padding: 25,
    },
  },
  customCard: {
    padding: 15,
    margin: 24,
    boxShadow: "rgba(175, 182, 194, 0.2) 0px 0px 100px",
    [theme.breakpoints.down("md")]: {
      padding: 5,
      margin: 15,
    },
    [theme.breakpoints.only("xs")]: {
      margin: "12.5px 25px",
    },
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  const { landingPage, lang } = useSelector((state) => state.rootReducer);

  const SideIcon = (props) => {
    return (
      <Grid container alignItems="center" className={classes.sideIcon}>
        <Grid item>
          <img src={props.icon} alt=""></img>
        </Grid>
        <Grid item xs>
          <Grid container style={{ textAlign: "left", paddingLeft: 25 }}>
            <Grid item xs={12} style={{ fontSize: 22, paddingBottom: 14 }}>
              {props.title}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: 16,
                lineHeight: "28px",
                color: "rgb(99, 109, 114)",
              }}
            >
              {props.subTitle}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const CustomCard = (props) => {
    return (
      <Card className={classes.customCard}>
        <Grid container>
          <Grid item xs={12}>
            <img src={props.icon} alt="" style={{ padding: 25 }}></img>
          </Grid>
          <Grid item xs={12} style={{ color: "#000", padding: "0px 25px" }}>
            {props.title}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ color: "rgb(99, 109, 114)", padding: 25 }}
          >
            {props.subTitle}
          </Grid>
        </Grid>
      </Card>
    );
  };

  const HowDoesItWork = () => {
    const copy = HOW_WORKS[lang];
    return (
      <Grid
        item
        xs={12}
        style={{ padding: "100px 0px 0px", textAlign: "center" }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid item xs={12} className={classes.subTitle}>
            {copy.subtitle}
          </Grid>
          <Grid container>
            <Grid item xs={12} md={1} sm={2}></Grid>
            <Grid item xs={12} md={10} sm={8}>
              <Grid container style={{ paddingTop: "70px" }}>
                {copy.list.map((l) => (
                  <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                    <CustomCard
                      icon={l.icon}
                      title={l.title}
                      subTitle={l.subtitle}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Protocols = () => {
    const copy = PROTOCOLS[lang];
    const chunks = copy.text.split("\n\n");
    const totalChunks = chunks.length;

    return (
      <Grid
        item
        xs={12}
        style={{ padding: "100px 0px 0px", textAlign: "center" }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid item xs={12} className={classes.subTitle}>
            {copy.subtitle}
          </Grid>
          <Grid container style={{ textAlign: "justify", paddingTop: 40 }}>
            <Grid item xs></Grid>
            <Grid item xs={12} sm={9} className={classes.subTitle}>
              {chunks.map((text, idx) =>
                idx + 1 !== totalChunks ? (
                  <React.Fragment>
                    {text}
                    <br></br>
                    <br></br>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {text}{" "}
                    {lang === "pt" ? (
                      <a
                        href="https://www.dges.gov.pt/pt/pagina/protocolos-de-alojamento"
                        alt="dges-protocols"
                      >
                        AQUI.
                      </a>
                    ) : (
                      <a
                        href="https://www.dges.gov.pt/pt/pagina/protocolos-de-alojamento"
                        alt="dges-protocols"
                      >
                        HERE.
                      </a>
                    )}
                  </React.Fragment>
                )
              )}
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Motivation = () => {
    const copy = MOTIVATION[lang];
    const chunks = copy.text.split("\n\n");
    const totalChunks = chunks.length;

    return (
      <Grid item xs={12} style={{ paddingTop: "100px", textAlign: "center" }}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid item xs={12} className={classes.subTitle}>
            {copy.subtitle}
          </Grid>
          <Grid container style={{ textAlign: "justify", paddingTop: 40 }}>
            <Grid item xs></Grid>
            <Grid item xs={12} sm={9} className={classes.subTitle}>
              {chunks.map((text, idx) =>
                idx + 1 !== totalChunks ? (
                  <React.Fragment>
                    {text}
                    <br></br>
                    <br></br>
                  </React.Fragment>
                ) : (
                  text
                )
              )}
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Features = () => {
    const copy = FEATURES[lang];

    return (
      <Grid
        item
        xs={12}
        style={{ padding: "100px 0px 0px", textAlign: "center" }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid item xs={12} className={classes.subTitle}>
            {copy.subtitle}
          </Grid>
          <Grid container>
            <Grid item xs={12} md={1} sm={2}></Grid>
            <Grid item xs={12} md={10} sm={8}>
              <Grid container style={{ marginTop: 70 }}>
                {copy.list.map((l) => (
                  <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                    <SideIcon
                      title={l.title}
                      subTitle={l.subtitle}
                      icon={l.icon}
                    ></SideIcon>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Methodology = () => {
    const copy = METHODOLOGY[lang];
    const chunks = copy.text.split("\n\n");
    const totalChunks = chunks.length;

    return (
      <Grid item xs={12} style={{ paddingTop: "100px", textAlign: "center" }}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid item xs={12} className={classes.subTitle}>
            {copy.subtitle}
          </Grid>
          <Grid container style={{ textAlign: "justify", paddingTop: 40 }}>
            <Grid item xs></Grid>
            <Grid item xs={12} sm={9} className={classes.subTitle}>
              {chunks.map((text, idx) =>
                idx + 1 !== totalChunks ? (
                  <React.Fragment>
                    {text}
                    <br></br>
                    <br></br>
                  </React.Fragment>
                ) : (
                  text
                )
              )}
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Support = () => {
    const copy = SUPPORT[lang];
    return (
      <Grid item xs={12} style={{ paddingTop: "100px", textAlign: "center" }}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid container style={{ textAlign: "justify", paddingTop: 40 }}>
            <Grid item xs></Grid>
            <Grid item xs={12} sm={9} className={classes.subTitle}>
              {copy.subtitle}
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={8} style={{ textAlign: "center" }}>
            <Grid container alignItems="center" style={{ padding: 25 }}>
              <Grid item xs={8}>
                <img
                  src={RepPortuguesa}
                  alt="portuguese-gov"
                  width="100%"
                ></img>
              </Grid>
              <Grid item xs={4}>
                <img
                  src={DGES}
                  alt="higher-education-agency"
                  width="100%"
                ></img>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Grid>
    );
  };

  const Alfredo = () => {
    const copy = ALFREDO[lang];
    const chunks = copy.text.split("\n\n");
    const totalChunks = chunks.length;

    return (
      <Grid item xs={12} style={{ paddingTop: "100px", textAlign: "center" }}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            {copy.title}
          </Grid>
          <Grid item xs={12} className={classes.subTitle}>
            {copy.subtitle}
          </Grid>
          <Grid container style={{ textAlign: "justify", paddingTop: 40 }}>
            <Grid item xs></Grid>
            <Grid item xs={12} sm={9} className={classes.subTitle}>
              {chunks.map((text, idx) =>
                idx + 1 !== totalChunks ? (
                  <React.Fragment>
                    {text}
                    <br></br>
                    <br></br>
                  </React.Fragment>
                ) : (
                  text
                )
              )}
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // only render component if drawer is open
  if (!landingPage) return <div></div>;

  return (
    <Grid
      container
      className={classes.container}
      justify="center"
      id="landing-page"
    >
      <Grid container className={classes.innerContainer}>
        <Motivation />
        <Protocols />
        <Features />
        <HowDoesItWork />
        <Methodology />
        <Alfredo />
        <Support />
      </Grid>
    </Grid>
  );
}
