import { makeStyles } from "@material-ui/styles";

const styles = (theme) => {
  return {
    container: {
      padding: "16px 16px 15px 16px",
      /*  [theme.breakpoints.only("xs")]: {
        padding: "35px 15px 30px",
      }, */
    },
    priceIntervalContainer: {
      padding: "8px 16px 15px 16px",
      /*  [theme.breakpoints.only("xs")]: {
        padding: "35px 15px 30px",
      }, */
    },
    reportContainer: {
      padding: "20px 0px",
    },
    svg: {
      width: 20,
      height: 15,
    },
    polygon: {
      fill: "rgb(0,157,224)",
    },
    title: {
      // fontFamily: "open sans",
      fontSize: "16px",
      color: "#484848",
      lineHeight: "22px",
      padding: "0px 15px 20px",
    },
    titleReport: {
      paddingBottom: 15,
      fontSize: "14px",
      color: "#484848",
    },
    lineOne: {
      // fontFamily: "Open Sans",
      fontSize: "42px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "0.83",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1c1c1c",
    },
    reportLineOne: {
      padding: 5,
      // fontFamily: "Open Sans",
      fontSize: "34px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "0.83",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1c1c1c",
    },
    lineTwo: {
      // fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "2.5",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#9e9d9d",
    },
    lineThreeCenter: {
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #00fff6",
    },
    lineThreeLeft: {
      // fontFamily: "Open Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 3,
      width: 85,
      letterSpacing: "normal",
      color: "#1c1c1c",
      textAlign: "left",
    },
    lineThreeRight: {
      // fontFamily: "Open Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 3,
      width: 85,
      letterSpacing: "normal",
      color: "#1c1c1c",
      textAlign: "right",
    },
    lineFour: {
      width: "100%",
      height: "10px",
      border: 0,
      borderRadius: "10px",
      margin: 0,
      backgroundImage: "linear-gradient(to left, rgb(0,157,224), rgb(0,157,224))",
      "-webkit-print-color-adjust": "exact",
      "color-adjust": "exact",
    },
    lineFive: {
      // fontFamily: "Open Sans",
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 2.45,
      letterSpacing: "normal",
      color: "#1c1c1c",
    },
    reportLineFive: {
      // fontFamily: "Open Sans",
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 2.45,
      letterSpacing: "normal",
      color: "#1c1c1c",
    },
    lineFiveLeft: {
      textAlign: "left",
      paddingBottom: "5px",
    },
    lineFiveRight: {
      textAlign: "right",
      paddingBottom: "5px",
    },
    lineSix: {
      // fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 0,
      letterSpacing: "normal",
      color: "#9e9d9d",
    },
    reportLineSix: {
      // fontFamily: "Open Sans",
      fontSize: "10px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 0,
      letterSpacing: "normal",
      color: "#9e9d9d",
    },
    lineSixLeft: {
      textAlign: "left",
    },
    lineSixRight: {
      textAlign: "right",
    },
    price: {
      color: "#484848",
      // fontFamily: "open sans",
      fontSize: "52px",
      lineHeight: "66px",
      height: "66px",
      textAlign: "center",
    },
    disclaimer: {
      // fontFamily: "open sans",
      fontSize: "14px",
      color: "#939AA3",
      maxWidth: "380px",
      display: "block",
      whiteSpace: "normal",
      margin: "8px auto",
      lineHeight: "16px",
      textAlign: "center",
    },
    description: {
      paddingTop: "10px",
      fontSize: 10,
      color: "#2e3c43",
    },
    descriptionListItem: {
      padding: "10px 0px 0px 0px",
    },
    showingContainer: {
      padding: "0px",
    },
  };
};
export default makeStyles(styles);
