import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";

import { WIDGETS } from "../../data/copy";

const CATEGORIES = {
  active: {
    reviews: "",
    rating: 0,
  },
  food: {
    reviews: "",
    rating: 0,
  },
  arts: {
    reviews: "",
    rating: 0,
  },
  health: {
    reviews: "",
    rating: 0,
  },
  transport: {
    reviews: "",
    rating: 0,
  },
  nightlife: {
    reviews: "",
    rating: 0,
  },
};

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 26,
    color: "#2e3c43",
    padding: "5px 0",
    fontFamily: "open sans",
  },
  description: {
    fontSize: 10,
    fontFamily: "open sans",
    color: "#2e3c43",
    padding: "15px 0px",
  },
  reviews: {
    fontSize: 10,
    fontFamily: "open sans",
    color: "#2e3c43",
    padding: "15px 15px",
  },
}));

const timeOut = (t) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(`Completed in ${t}`);
    }, t);
  });
};

const requestData = async (latitude, longitude, category, radius, idx) => {
  // Wait for the sleep promise to resolve

  await timeOut(idx * 1000);

  const proxy = "https://cors-anywhere.sys.alfredo.pt/";
  const target = `https://api.yelp.com/v3/businesses/search?latitude=${latitude}&longitude=${longitude}&categories=${category}&radius=${radius}`;
  const apiKeyYelp =
    "-WldS8cKWAXksdndaJOweplB8bGHHQHhofmJcnNBYvg3Kx77e9IwSpwmbGf_D0B3y25E21pnGY9TziM9bm6Gh9rldJpVhbDL6e9_0o52tVtDarwpMXjFv27TN8cNWnYx";

  const res = await axios
    .get(`${proxy}${target}`, {
      headers: {
        Authorization: `Bearer ${apiKeyYelp}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {});

  const review_count = [];
  const rating_entry = [];

  if (res?.businesses.length > 0) {
    res.businesses.forEach((business) => {
      review_count.push(business.review_count);
      rating_entry.push(business.rating);
    });
  }

  const reviews = review_count.reduce((a, b) => a + b, 0);
  const ratings = rating_entry.reduce((a, b) => a + b, 0);

  const rating = ratings / rating_entry.length || 0;

  /* let reviews_min = Math.ceil(50);
  let reviews_max = Math.floor(500);
  let reviews =
    Math.floor(Math.random() * (reviews_max - reviews_min)) + reviews_min;

  let rating_min = Math.ceil(3);
  let rating_max = Math.floor(5);
  let rating =
    Math.floor(Math.random() * (rating_max - rating_min)) + rating_min; */

  return { reviews, rating, categ: category };
};

const WidgetNearby = (props) => {
  const { setLoading } = props;
  const classes = useStyles();
  const { mapConfigs, sectionCenter, lang } = useSelector((state) => state.rootReducer);

  const copy = WIDGETS[lang];
  const center = sectionCenter || mapConfigs.center;
  const [categoriesReviews, setCategoriesReviews] = useState(CATEGORIES);

  let radius = 4000;
  if (sectionCenter) {
    radius = 1000;
  }

  useEffect(() => {
    setLoading(true);

    const promises = [];

    Object.keys(categoriesReviews).forEach((category, idx) => {
      promises.push(requestData(center[1], center[0], category, radius, idx));
    });

    Promise.all([...promises]).then((res) => {
      const updatedCategories = JSON.parse(JSON.stringify(categoriesReviews));
      res.forEach((cat) => {
        const { reviews, rating, categ } = cat;

        if (categ in updatedCategories) updatedCategories[categ] = { reviews, rating };

        setLoading(false);
      });
      setCategoriesReviews(updatedCategories);
    });
  }, [center]);

  return (
    <div>
      <ListItem style={{ padding: "0px 16px" }}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            {Object.keys(categoriesReviews).map((cat) => (
              <Grid container alignItems="center" key={cat}>
                <Grid item xs style={{ fontSize: "12px" }}>
                  {copy[cat]}
                </Grid>
                <Grid item className={classes.reviews}>
                  {categoriesReviews[cat].reviews} {copy.rating}
                </Grid>
                <Grid item>
                  <Rating
                    name="read-only"
                    value={categoriesReviews[cat].rating}
                    precision={0.5}
                    readOnly
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.description}>
              {copy.source}: Yelp Fusion
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
};

export default WidgetNearby;
