import { createStore, combineReducers } from "redux";
import rootReducer from "./rootReducer";

const reducer = combineReducers({
  rootReducer: rootReducer,
});

const store = createStore(reducer);

export default store;
