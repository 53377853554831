import React, { useRef, useEffect, useState } from "react";
import { setKeyValue, handleClickPoint, handleClickSection } from "../../redux/rootReducer/actions";
import { useSelector, useDispatch } from "react-redux";
import { MAP_CARDS } from "../../data/copy";
import styled from "styled-components";
import "./styles.css";

const Mapbox = (props) => {
  const { requestCountyData, requestDistributionData } = props;

  const [savedSourceIDs, saveSourceIDs] = useState([]);
  const [savedMarkers, saveMarkers] = useState({});
  let savedSectionSelected = null; // hack so we can access it inside event listener

  const dispatch = useDispatch();

  const { sectionSelected, pointSelected, mapConfigs, countyID, sources, markers, layer, lang } =
    useSelector((state) => state.rootReducer);

  const copy = MAP_CARDS[lang];

  const mapContainer = useRef(null);
  const map = useRef(null);

  let hoveredStateId = null;

  useEffect(() => {
    if (map.current) return; // initialize map only once

    window.mapboxgl.accessToken =
      "pk.eyJ1IjoibWFyaW9kdWFydGUiLCJhIjoiY2l5MXRzNGtxMDBjZjMybzdjNWRneDhoNiJ9.Ntz9yxEPIQvE0UHhhVcBEQ";

    map.current = new window.mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/uberdata/cjoqbbf6l9k302sl96tyvka09",
      ...mapConfigs,
    });

    // if default count is selected and no sources, request sources
    if (!Object.keys(sources).length && countyID) {
      requestCountyData(countyID);
      requestDistributionData(countyID, sectionSelected);
    }

    dispatch(setKeyValue("mapRef", map.current));
  }, []);

  // add/update sources
  useEffect(() => {
    if (Object.keys(sources).length === 0) return;

    if (savedSourceIDs) {
      clearLayers(savedSourceIDs);
    }

    Object.keys(sources)
      .reverse()
      .forEach((sourceID) => {
        addSources(sourceID, sources[sourceID]);
        addLayers(sourceID);
      });

    saveSourceIDs(Object.keys(sources));
  }, [sources]);

  // add/update markers
  useEffect(() => {
    if (Object.keys(markers).length === 0) return;
    addMarkers(markers);
  }, [markers]);

  // recenter map on countyID selection
  useEffect(() => {
    clearSources(savedSourceIDs);
    map.current.flyTo({ ...mapConfigs });

    dispatch(setKeyValue("layer", "p"));
  }, [countyID]);

  useEffect(() => {
    Object.keys(sources)
      .reverse()
      .forEach((sourceID) => {
        if (map.current.getLayer(`${sourceID}-fills`)) {
          map.current.setPaintProperty(
            `${sourceID}-fills`,
            "fill-opacity",
            layer === "n" ? ["case", ["boolean", ["feature-state", "hover"], false], 0.5, 0.2] : 0
          );
        }

        if (map.current.getLayer(`${sourceID}-fills-dimmed-price`)) {
          map.current.setLayoutProperty(
            `${sourceID}-fills-dimmed-price`,
            "visibility",
            layer === "p" ? "visible" : "none"
          );
          map.current.setLayoutProperty(
            `${sourceID}-fills-dimmed-price-highlighted`,
            "visibility",
            layer === "p" ? "visible" : "none"
          );
        }

        if (map.current.getLayer(`${sourceID}-fills-dimmed-absortion`)) {
          map.current.setLayoutProperty(
            `${sourceID}-fills-dimmed-absortion`,
            "visibility",
            layer === "a" ? "visible" : "none"
          );
          map.current.setLayoutProperty(
            `${sourceID}-fills-dimmed-absortion-highlighted`,
            "visibility",
            layer === "a" ? "visible" : "none"
          );
        }
      });
  }, [layer]);

  const clearSources = (sources) => {
    if (sources.length === 0) return;
    clearLayers(savedSourceIDs);
    sources.forEach((id) => {
      if (map.current.getSource(id)) map.current.removeSource(id);
    });
    Object.keys(savedMarkers).forEach((key) => savedMarkers[key].remove());
    saveMarkers({});
  };

  const clearLayers = (sources) => {
    map.current.getStyle().layers.forEach((layer) => {
      if (sources.includes(layer.source)) {
        map.current.off("mouseup", layer.id, onClickLayer);
        map.current.off("mousemove", layer.id, onHoverLayer);
        map.current.off("mouseleave", layer.id, onLeaveLayer);

        map.current.removeLayer(layer.id);
      }
    });
  };

  const addSources = (id, source) => {
    if (map.current.getSource(id)) {
      map.current.getSource(id).setData(Object.assign({}, source));
    } else map.current.addSource(id, source);
  };

  const addLayers = (source) => {
    //sources include parishes and areas
    if (!map.current.getLayer(`${source}-borders`)) {
      map.current.addLayer({
        id: `${source}-borders`,
        type: "line",
        source,
        paint: {
          "line-color": source === `${countyID}-areas` ? "#fff" : "#fff",
          "line-opacity": source === `${countyID}-areas` ? 0.1 : 1,
          "line-width": source === `${countyID}-areas` ? 1 : 1.5,
        },
        layout: {
          visibility: "visible",
        },
      });
    }

    if (source === `${countyID}-parishes`) {
      map.current.addLayer(
        {
          id: `${source}-fills`,
          type: "fill",
          source,
          paint: {
            "fill-color": "#009de0",
            "fill-opacity":
              layer !== "n"
                ? 0
                : ["case", ["boolean", ["feature-state", "hover"], false], 0.5, 0.2],
          },
          layout: {
            visibility: "visible",
            //visibility: layer === "n" ? "visible" : "none",
          },
        },
        `${source}-borders`
      );

      map.current.on("mousemove", `${source}-fills`, onHoverLayer(source));
      map.current.on("mouseleave", `${source}-fills`, onLeaveLayer(source));
      map.current.on("mouseup", `${source}-fills`, onClickLayer);
    } else {
      map.current.addLayer({
        id: `${source}-fills-dimmed-price`,
        type: "fill",
        source,
        paint: {
          "fill-color": {
            base: 1,
            type: "interval",
            property: "priceNorm",
            stops: [
              [-1, "#484848"],
              [-0.15, "#1a9850"],
              [-0.1, "#a6d96a"],
              [0, "#ffffbf"],
              [0.1, "#fdae61"],
              [0.15, "#d73027"],
            ],
          },
          "fill-opacity": 0.2,
        },
        layout: {
          visibility: layer === "p" ? "visible" : "none",
        },
      });

      map.current.addLayer({
        id: `${source}-fills-dimmed-price-highlighted`,
        type: "fill",
        source,
        filter: ["in", "parish_id", ""],
        paint: {
          "fill-color": {
            base: 1,
            type: "interval",
            property: "priceNorm",
            stops: [
              [-1, "#484848"],
              [-0.15, "#1a9850"],
              [-0.1, "#a6d96a"],
              [0, "#ffffbf"],
              [0.1, "#fdae61"],
              [0.15, "#d73027"],
            ],
          },
          "fill-opacity": 0.5,
        },
        layout: {
          visibility: layer === "p" ? "visible" : "none",
        },
      });

      map.current.addLayer({
        id: `${source}-fills-dimmed-absortion`,
        type: "fill",
        source,
        paint: {
          "fill-color": {
            base: 1,
            type: "interval",
            property: "absorptionTime",
            stops: [
              [-1, "#484848"],
              [0, "#d73027"],
              [60, "#fdae61"],
              [90, "#ffffbf"],
              [120, "#a6d96a"],
              [3650, "#1a9850"],
            ],
          },
          "fill-opacity": 0.2,
        },
        layout: {
          visibility: layer === "a" ? "visible" : "none",
        },
      });

      map.current.addLayer({
        id: `${source}-fills-dimmed-absortion-highlighted`,
        type: "fill",
        source,
        filter: ["in", "parish_id", ""],
        paint: {
          "fill-color": {
            base: 1,
            type: "interval",
            property: "absorptionTime",
            stops: [
              [-1, "#484848"],
              [0, "#d73027"],
              [60, "#fdae61"],
              [90, "#ffffbf"],
              [120, "#a6d96a"],
              [3650, "#1a9850"],
            ],
          },
          "fill-opacity": 0.5,
        },
        layout: {
          visibility: layer === "a" ? "visible" : "none",
        },
      });
    }
  };

  const onHoverLayer = (source) => (e) => {
    if (savedSectionSelected) return;

    map.current.getCanvas().style.cursor = "pointer";
    if (e.features.length > 0) {
      if (hoveredStateId !== null) {
        map.current.setFeatureState({ source, id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = e.features[0].id;
      map.current.setFeatureState({ source, id: hoveredStateId }, { hover: true });
    }

    const neighborhood = e.features[0].properties.parish_id;
    map.current.setFilter(`${countyID}-areas-fills-dimmed-price-highlighted`, [
      "in",
      "parish_id",
      neighborhood,
    ]);
    map.current.setFilter(`${countyID}-areas-fills-dimmed-absortion-highlighted`, [
      "in",
      "parish_id",
      neighborhood,
    ]);
  };

  const onLeaveLayer = (source) => (e) => {
    if (savedSectionSelected) return;

    map.current.getCanvas().style.cursor = "";

    if (hoveredStateId !== null) {
      map.current.setFeatureState({ source, id: hoveredStateId }, { hover: false });
      map.current.setFilter(`${countyID}-areas-fills-dimmed-price-highlighted`, [
        "in",
        "parish_id",
        "",
      ]);
      map.current.setFilter(`${countyID}-areas-fills-dimmed-absortion-highlighted`, [
        "in",
        "parish_id",
        "",
      ]);
    }
    hoveredStateId = null;
  };

  const onClickLayer = (e) => {
    const parishID = e.features[0].properties.code;
    const parishIdx = e.features[0].properties.idx;
    if (savedSectionSelected === parishID) {
      dispatch(handleClickSection(null, parishIdx));
      savedSectionSelected = null;
    } else {
      dispatch(handleClickSection(parishID, parishIdx));
      savedSectionSelected = parishID;
    }
  };

  const addMarkers = (marks) => {
    marks.forEach((marker, idx) => {
      const el = document.createElement("div");
      el.className = "marker";

      el.style.backgroundImage = "url(" + marker.properties.icon.iconUrl + ")";
      el.style.width = marker.properties.icon.iconSize[0] + "px";
      el.style.height = marker.properties.icon.iconSize[1] + "px";

      const popupTitle =
        marker.properties.id.indexOf("residences") !== -1 ? copy.residence : copy.ies;

      const popupButton = marker.properties.website
        ? `<a href="${marker.properties.website}" target="_blank" class="button-popup">${copy.website}</a>`
        : "";

      const owner = marker.properties.owner
        ? `<p><b>${copy.residenceOwner}:</b> ${marker.properties.owner}</p>`
        : "";

      const email = marker.properties.email
        ? `<p><b>Email:</b> ${marker.properties.email}</p>`
        : "";

      const totalBeds = marker.properties.totalBeds
        ? `<p><b>${copy.totalBeds}:</b> ${marker.properties.totalBeds}</p>`
        : "";

      const covidBeds = marker.properties.covidBeds
        ? `<p><b>${copy.totalBedsCovid}:</b> ${marker.properties.covidBeds}</p>`
        : "";

      const popupHTML = `
      <div class="card">
        <img src="${marker.properties.icon.iconUrl}" alt=${
        marker.properties.title
      } style="height:60px">
        <div class="container">
          <h4 class="pop-title"><b>${popupTitle}</b></h4>
          <p>${marker.properties.name} </p>
          <div class="content">
            ${owner}
            <p><b>${copy.address}:</b> ${marker.properties.address} </p>
            ${totalBeds}
            ${covidBeds}
            ${popupTitle !== "Instituição de Ensino Superior" ? email : ""}
          </div>
          ${popupButton}
        </div>
      </div>`;

      const markerRef = new window.mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new window.mapboxgl.Popup({ offset: 5 }) // add popups
            .setHTML("<h3>" + marker.properties.name + "</h3><p>" + "</p>")
            .setHTML(popupHTML)
        )
        .addTo(map.current);

      savedMarkers[marker.properties.id] = markerRef;

      el.addEventListener("click", function (e) {
        e.stopPropagation();

        dispatch(handleClickPoint(null));
        dispatch(handleClickPoint(marker.properties.id));
      });
    });
  };

  const handlePointSelection = () => {
    Object.keys(savedMarkers).map((m) => savedMarkers[m].getPopup().remove());
    const marker = savedMarkers[pointSelected];

    marker.togglePopup();
    const { lng, lat } = marker.getLngLat();

    // adjust zoom and center on mobile
    const zoom = window.innerWidth <= 600 ? 11 : 14;
    const center = window.innerWidth <= 600 ? [lng, lat - 0.025] : [lng, lat];

    map.current.flyTo({ center, zoom });
  };

  const handleSectionSelection = () => {
    const sourceParishes = sources[`${countyID}-parishes`];
    let parish = null;

    if (!sourceParishes) return;

    sourceParishes.data.features.forEach((feature) => {
      if (feature.internal_id === sectionSelected) {
        map.current.setFeatureState(
          { source: `${countyID}-parishes`, id: feature.id },
          { hover: true }
        );

        const neighborhood = feature.properties.parish_id;
        map.current.setFilter(`${countyID}-areas-fills-dimmed-price-highlighted`, [
          "in",
          "parish_id",
          neighborhood,
        ]);
        map.current.setFilter(`${countyID}-areas-fills-dimmed-absortion-highlighted`, [
          "in",
          "parish_id",
          neighborhood,
        ]);

        parish = feature;
      } else {
        map.current.setFeatureState(
          { source: `${countyID}-parishes`, id: feature.id },
          { hover: false }
        );
      }
    });

    if (!parish) return; // avoid map break

    // avoid zoom and recenter on mobile
    const zoom = window.innerWidth <= 600 ? 11.5 : 14;
    const center =
      window.innerWidth <= 600
        ? [parish.properties.centroid[0], parish.properties.centroid[1] - 0.025]
        : parish.properties.centroid;

    dispatch(setKeyValue("sectionCenter", center));

    map.current.flyTo({ center, zoom });
  };

  const baselineMapView = () => {
    dispatch(setKeyValue("sectionCenter", null));
    map.current.flyTo({ ...mapConfigs });
    //onLeaveLayer();
  };

  useEffect(() => {
    if (pointSelected) handlePointSelection();
    else if (sectionSelected) handleSectionSelection();
    else baselineMapView();
  }, [pointSelected, sectionSelected]);

  return <Container ref={mapContainer} />;
};

const Container = styled.div`
  border-radius: 5px;
  height: 100%;
  width: 100%;

  @media (max-width: 600px) {
    border-radius: 0px;
  }
`;

export default Mapbox;
