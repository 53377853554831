import {
  HANDLE_CLICK_SECTION,
  HANDLE_SELECT_COUNTY,
  HANDLE_CLICK_POINT,
  SET_KEY_VALUE,
  SET_LOADING,
  ADD_SOURCES,
  ADD_MARKERS,
  SET_DISTRIBUTION_LOADING,
  SET_ANIMATE,
} from "./constants";

export const setAnimate = (animate) => ({
  type: SET_ANIMATE,
  animate,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setDistributionLoading = (distributionLoading) => ({
  type: SET_DISTRIBUTION_LOADING,
  distributionLoading,
});

export const addSources = (sources) => ({
  type: ADD_SOURCES,
  sources,
});

export const addMarkers = (markers) => ({
  type: ADD_MARKERS,
  markers,
});

export const handleClickPoint = (pointID, idx, sectionID = null) => {
  return {
    type: HANDLE_CLICK_POINT,
    sectionID,
    pointID,
    idx,
  };
};

export const handleClickSection = (sectionID, idx, pointID = null) => ({
  type: HANDLE_CLICK_SECTION,
  sectionID,
  pointID,
  idx,
});

export const handleSelectCounty = (countyID, center, zoom) => ({
  type: HANDLE_SELECT_COUNTY,
  countyID,
  center,
  zoom,
});

export const setKeyValue = (key, value) => ({
  type: SET_KEY_VALUE,
  key,
  value,
});
