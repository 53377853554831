import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { WIDGETS } from "../../data/copy";

import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  YAxis,
  XAxis,
  LineChart,
  Legend,
  Line,
} from "recharts";

import moment from "moment";
import "moment/locale/pt";
moment.locale("pt");

const useStyles = makeStyles((theme) => ({
  tooltip: {
    textTransform: "capitalize",
    background: "white",
    borderRadius: 3,
    fontSize: 10,
    padding: 5,
  },
  description: {
    fontSize: 10,
    fontFamily: "open sans",
    color: "#2e3c43",
    paddingBottom: "15px",
  },
  showingContainer: {
    padding: "8px 16px 15px",
  },
}));

const WidgetGraph = (props) => {
  const classes = useStyles();
  const { data, source, id, sectionName, section } = props;
  const { lang } = useSelector((state) => state.rootReducer);
  const copy = WIDGETS[lang];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.label}>{`${moment(label).format("MMMM YYYY")}`}</p>
          {payload
            .slice(0)
            .reverse()
            .map((entry, idx) => (
              <p style={{ fontSize: 10 }} key={idx}>
                <span
                  style={{
                    color: { 0: "#1785FB", 1: "#fb8d17", 2: "#009de0" }[idx],
                  }}
                >
                  {entry.name}:
                </span>{" "}
                {entry.payload[entry.dataKey]} {props.units}
              </p>
            ))}
        </div>
      );
    }

    return null;
  };

  const seriesName = (id) => {
    switch (id) {
      case "min":
        return copy.minPrice;
      case "mean":
        return copy.avgPrice;
      case "max":
        return copy.maxPrice;
      default:
        return "";
    }
  };

  const maxY =
    data &&
    Math.max.apply(
      Math,
      data.map((o) => o.y)
    );
  const maxX =
    data &&
    Math.max.apply(
      Math,
      data.map((o) => o.x)
    );
  const minX =
    data &&
    Math.min.apply(
      Math,
      data.map((o) => o.x)
    );
  const minY =
    data &&
    Math.min.apply(
      Math,
      data.map((o) => o.y)
    );

  return !data || data.length < 1 ? (
    <ListItem className={classes.showingContainer}>
      <ListItemText primary={copy.noResults} />
    </ListItem>
  ) : (
    <React.Fragment>
      <div style={{ width: "100%", height: 250, padding: "5px 0" }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 25,
              right: 30,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="x"
              domain={["auto", "auto"]}
              name="Time"
              tickFormatter={(unixTime) => moment(unixTime).format("MMM/YY")}
              style={{ fontSize: 10 }}
            />
            <YAxis
              domain={[minY ? parseInt(minY * 0.9) : 0, maxY ? parseInt(maxY * 1.1) : "auto"]}
              tickFormatter={(value) => Math.round(value / 100) * 100}
              style={{ fontSize: 10 }}
            />
            <Tooltip content={<CustomTooltip />} />
            {Object.keys(data[0]).length > 2 && <Legend wrapperStyle={{ fontSize: 10 }} />}
            {Object.keys(data[0]).map(
              (key, idx) =>
                key !== "x" && (
                  <Line
                    key={key}
                    type="monotone"
                    name={seriesName(key)}
                    dataKey={key}
                    stroke={{ 0: "#1785FB", 1: "#009DE0", 2: "#fb8d17" }[idx]}
                  />
                )
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <ListItem style={{ padding: "10px 16px 0" }}>
        <Grid container>
          <Grid item xs={12} className={classes.description}>
            {copy[id] && `${copy[id]}: ${sectionName} (${copy[section]})`}
          </Grid>
          {source && (
            <Grid item xs={12} className={classes.description}>
              {copy.source}: {source}
            </Grid>
          )}
        </Grid>
      </ListItem>
    </React.Fragment>
  );
};

export default WidgetGraph;
