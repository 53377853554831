import React, { useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef } from "react";
import moment from "moment";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const AdminTable = (props) => {
  const { copy, data, setData, handleUpdate } = props;

  const [columns, setColumns] = useState([
    {
      title: copy.residence,
      field: "name",
      editable: "never",
    },
    {
      title: copy.totalBeds,
      field: "totalBeds",
      type: "numeric",
      align: "left",
      validate: (rowData) =>
        rowData.totalBeds >= 0 && rowData.totalBeds >= rowData.occupiedBeds,
      render: (rowData) => (rowData.totalBeds ? rowData.totalBeds : 0),
    },
    {
      title: copy.occupiedBeds,
      field: "occupiedBeds",
      type: "numeric",
      align: "left",
      validate: (rowData) =>
        rowData.occupiedBeds >= 0 && rowData.occupiedBeds <= rowData.totalBeds,
      render: (rowData) => (rowData.occupiedBeds ? rowData.occupiedBeds : 0),
    },
    {
      title: copy.lastUpdate,
      field: "lastUpdate",
      editable: "never",
      render: (rowData) => {
        var date = moment(rowData.lastUpdate).format("DD-MM-YYYY");
        return <div>{date}</div>;
      },
    },
  ]);

  return (
    <MaterialTable
      localization={{
        header: { actions: copy.actions },
        pagination: {
          labelRowsSelect: copy.labelRowsSelect,
          labelRowsPerPage: copy.labelRowsPerPage,
        },
      }}
      style={{ width: "1200px" }}
      title={copy.institutions}
      filterPlaceholder={copy.filterPlaceholder}
      icons={tableIcons}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        actionsColumnIndex: -1,
        headerStyle: {
          fontWeight: 700,
        },
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            handleUpdate(newData, oldData, resolve, reject);
          }),
      }}
    />
  );
};

export default AdminTable;
