import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";

import { Grid, ListItem, ListItemText, Chip } from "@material-ui/core";
import useStyles from "./styles";

import Requester from "../../helpers/Requester";

import { WIDGETS } from "../../data/copy";

import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ReferenceLine,
  Label,
} from "recharts";

const WidgetDistribution = (props) => {
  const { mobile, id, sectionName, section, histograms, loading, animate, setAnimate } = props;
  const { lang } = useSelector((state) => state.rootReducer);

  const classes = useStyles();

  const copy = WIDGETS[lang];
  const [distributionRange, setDistributionRange] = useState({
    min: null,
    mean: null,
    max: null,
  });

  const [featuresDiff, setFeaturesDiff] = useState({
    expensesIncluded: 0,
    furnished: 0,
    equippedKitchen: 0,
    doubleBed: 0,
    cleaningService: 0,
  });

  const [toggledFeatures, setToggledFeatures] = useState([]);
  const [plot, setPlot] = useState([]);

  const allFeatures = [
    "expensesIncluded",
    "furnished",
    "equippedKitchen",
    "doubleBed",
    "cleaningService",
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={classes.tooltip}>
          {payload.length >= 1 && (
            <p
              className={classes.label}
            >{`${copy.roomsPriceInterval}: ${payload[0].payload.x0}-${payload[0].payload.x} €`}</p>
          )}
          {payload.map((entry) => (
            <p style={{ fontSize: 10 }}>
              <span
                style={{
                  color: entry.stroke,
                }}
              >
                {`${entry.value} ${copy.rooms}`}
              </span>
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const PriceRange = () => (
    <Grid
      container
      className={classes.distributionRange}
      justify="space-between"
      alignItems="center"
    >
      {distributionRange && distributionRange.mean > 0 ? (
        <>
          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.descMin}>
                {copy.minValue}
              </Grid>
              <Grid item className={classes.min}>
                {`${distributionRange.min} €`}
              </Grid>
            </Grid>
          </Grid>

          <svg className={classes.svg}>
            <polygon points="12,0 0,6 12,12" className={classes.polygon} />
          </svg>

          <Grid item className={classes.mean}>
            {`${distributionRange.mean} €`}
          </Grid>

          <svg className={classes.svg}>
            <polygon points="0,0 12,6 0,12" className={classes.polygon} />
          </svg>

          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.descMax}>
                {copy.maxValue}
              </Grid>
              <Grid item className={classes.max}>
                {`${distributionRange.max} €`}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <ListItem className={classes.showingContainer}>
            <ListItemText primary={copy.noRooms} className={classes.textNoRooms} />
          </ListItem>
        </Grid>
      )}
    </Grid>
  );

  const areSetsEqual = (a, b) => a.size === b.size && [...a].every((value) => b.has(value));
  const getToggledFeatureSet = () => new Set(toggledFeatures);

  useEffect(() => {
    if (histograms && histograms.length > 0) {
      const toggledFeatureSet = getToggledFeatureSet();
      histograms.forEach((histogram) => {
        if (areSetsEqual(new Set(histogram.features), toggledFeatureSet)) {
          setPlot([...histogram.histogram]);

          if (histogram.diff && featuresDiff !== histogram.diff) {
            setFeaturesDiff(histogram.diff);
          }

          if (
            !distributionRange ||
            distributionRange.min !== histogram.min ||
            distributionRange.mean !== histogram.mean ||
            distributionRange.max !== histogram.max
          ) {
            setDistributionRange({
              ...distributionRange,
              min: histogram.min,
              mean: histogram.mean,
              max: histogram.max,
            });
          }
        }
      });
    } else {
      setPlot([]);
      setDistributionRange({
        min: null,
        mean: null,
        max: null,
      });
    }
  }, [histograms, toggledFeatures]);

  return (
    <Grid container>
      {plot.length > 0 ? (
        <>
          <Grid item xs={12}>
            <PriceRange />
          </Grid>
          <Grid item xs={12}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart
                data={plot}
                margin={{
                  top: 25,
                  right: 30,
                  left: -20,
                }}
              >
                <XAxis
                  dataKey="x"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  style={{ fontSize: 10 }}
                  tickFormatter={(price) => `${price} €`}
                />
                <Area
                  dataKey="y0"
                  stroke="#fb8d17"
                  fill="rgba(251, 141, 23, 0.15)"
                  type="monotoneX"
                  name={copy.baseDistribution}
                  isAnimationActive={animate}
                  onAnimationEnd={() => setAnimate(false)}
                />
                {"y" in plot[0] && (
                  <Area
                    dataKey="y"
                    stroke="#009DE0"
                    fill="rgba(0, 157, 224, 0.8)"
                    type="monotoneX"
                    isAnimationActive={true}
                    name={copy.filteredDistribution}
                  />
                )}
                <Tooltip content={<CustomTooltip />} />
                <YAxis
                  type="number"
                  scale="sqrt"
                  domain={[0, "dataMax"]}
                  style={{ fontSize: 10 }}
                />
                <ReferenceLine
                  x={distributionRange && distributionRange.mean}
                  isFront={true}
                  stroke={"y" in plot[0] ? "#1785FB" : "#fb8d17"}
                  strokeWidth={2}
                >
                  <Label
                    value={copy.mean}
                    fill={"y" in plot[0] ? "#1785FB" : "#fb8d17"}
                    offset={7}
                    position="top"
                  />
                </ReferenceLine>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend wrapperStyle={{ fontSize: 10, paddingLeft: 60 }} align="left" />
              </AreaChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.chipContainer}
              justify={mobile ? null : "space-evenly"}
            >
              {allFeatures.map((feature) => {
                let label = copy[feature];
                if (featuresDiff[feature] && Number.isInteger(featuresDiff[feature])) {
                  if (featuresDiff[feature] > 0) {
                    label = `${label} (+${featuresDiff[feature]} €)`;
                  } else if (featuresDiff[feature] < 0) {
                    label = `${label} (${featuresDiff[feature]} €)`;
                  } else {
                    label = `${label} (~ €)`;
                  }
                } else {
                  label = `${label} (~ €)`;
                }

                return (
                  <Grid item xs={mobile ? 12 : null} className={classes.chipItem}>
                    <Chip
                      clickable={!loading}
                      className={mobile ? classes.chipMobile : classes.chip}
                      key={feature}
                      label={label}
                      // label={copy[feature]}
                      color={toggledFeatures.includes(feature) ? "primary" : "default"}
                      onClick={() => {
                        const toggledFeaturesSet = getToggledFeatureSet();
                        if (toggledFeaturesSet.has(feature)) {
                          toggledFeaturesSet.delete(feature);
                        } else {
                          toggledFeaturesSet.add(feature);
                        }
                        setToggledFeatures([...toggledFeaturesSet]);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              {copy[id] && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className={classes.description}>
                      {`${copy[id]}: ${sectionName} (${copy[section]})`}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        !loading && (
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <ListItem className={classes.showingContainer}>
                <ListItemText primary={copy.noResults} />
              </ListItem>
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default WidgetDistribution;
