import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { setKeyValue } from "../../redux/rootReducer/actions";
import ClearLayerIcon from "@material-ui/icons/LayersClear";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import LayerIcon from "@material-ui/icons/Layers";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

const IconSelector = () => {
  const { layer } = useSelector((state) => state.rootReducer);
  const dispatch = useDispatch();

  const handleChange = (event, v) => {
    if (!v) return;
    dispatch(setKeyValue("layer", v));
  };

  return (
    <Container container>
      <ToggleButtonGroupCustom
        onChange={handleChange}
        className="toggle"
        value={layer}
        size="small"
        exclusive
      >
        <ToggleButton value="n" className="btn">
          <ClearLayerIcon />
        </ToggleButton>
        <ToggleButton value="p" className="btn">
          <LayerIcon />P
        </ToggleButton>
        <ToggleButton value="a" className="btn">
          <LayerIcon />A
        </ToggleButton>
      </ToggleButtonGroupCustom>
    </Container>
  );
};

const Container = styled(Grid)`
  padding-top: 5px;
`;

const ToggleButtonGroupCustom = styled(ToggleButtonGroup)`
  width: 100%;

  .btn {
    border-radius: 3px;
    color: white;
    width: 100%;
  }

  .Mui-selected {
    background-color: #009de0 !important;
  }
`;

export default IconSelector;
