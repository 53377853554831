import axios from "axios";

const ABORT_ERROR = "ABORTED";

class Requester {
  _baseUrl = "";
  _options = {};
  _cancelKeys = {};

  constructor(baseUrl, headers) {
    this._baseUrl = baseUrl;
    if (headers) {
      this._options.headers = headers;
    }
  }

  send = (
    urlPath,
    data,
    {
      cancelKey,
      log,
      request = { request: { method: "GET", timeout: 7000 } },
      useBody = false,
      extraHeaders = null,
    } = {}
  ) => {
    if (extraHeaders) {
      this._options.headers = { ...this._options.headers, ...extraHeaders };
    }

    if (cancelKey) {
      this.cancelRequest(cancelKey);
      this._cancelKeys[cancelKey] = axios.CancelToken.source();
      request.cancelToken = this._cancelKeys[cancelKey].token;
    }

    if (useBody && data) {
      request.data = data;
    } else if (data) {
      const paramKeys = Object.keys(data);
      if (paramKeys.length !== 0) urlPath += "?";

      urlPath += paramKeys
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              data[key].toString()
            )}`
        )
        .join("&");
    }

    const url = `${this._baseUrl}/${urlPath}`;
    return axios({
      ...this._options,
      ...request,
      url,
    })
      .then((response) => response.data)
      .catch((error) => {
        // Error 😨

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */

          return Promise.reject(error.response);
        }
      });
  };

  get = ({ urlPath, params, options, headers }) =>
    this.send(urlPath, params, {
      ...options,
      useBody: false,
      extraHeaders: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });

  delete = ({ urlPath, params, options, headers }) =>
    this.send(urlPath, params, {
      ...options,
      useBody: true,
      extraHeaders: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
      request: { method: "DELETE", timeout: 7000 },
    });

  post = ({ urlPath, params, options, headers }) =>
    this.send(urlPath, params, {
      ...options,
      useBody: true,
      request: { method: "POST", timeout: 70000 },
      extraHeaders: { ...headers },
    });

  cancelAll = () => {
    const keys = Object.keys(this._cancelKeys);
    keys.forEach((cancelKey) => {
      this.cancelRequest(cancelKey);
    });
  };

  cancelRequest = (cancelKey) => {
    if (this._cancelKeys[cancelKey]) {
      this._cancelKeys[cancelKey].cancel(ABORT_ERROR);
      delete this._cancelKeys[cancelKey];
    }
  };
}

export default Requester;
