import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import IconSelector from "./IconSelector";
import AutoComplete from "./AutoComplete";
import { useSelector } from "react-redux";
import polyline from "@mapbox/polyline";

import { WIDGETS } from "../../data/copy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  text: {
    fontSize: 26,
    color: "#2e3c43",
    padding: "5px 0",
    fontFamily: "open sans",
  },
  autoComplete: {
    padding: "5px 0",
  },
  description: {
    fontSize: 10,
    fontFamily: "open sans",
    color: "#2e3c43",
    paddingBottom: "15px",
  },
  warning: {
    padding: "0px 0px 10px",
    fontSize: 12,
    color: "#2e3c43",
    display: "flex",
    alignItems: "center",
  },
}));

const requestData = async (travelModel, origin, destination) => {
  const proxy = "https://cors-anywhere.sys.alfredo.pt/";
  const target = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&mode=${travelModel}`;
  const apiKeyGoogle = "&key=AIzaSyAmFVxkjuJggwqq2Sqz3915bK5fXAdl9cI";
  const resp = await fetch(`${proxy}${target}${apiKeyGoogle}`);
  const respJson = await resp.json();

  return respJson;
};

const WidgetDistance = (props) => {
  const { setLoading, setHasData } = props;
  const classes = useStyles();
  const { mapRef, mapConfigs, sectionCenter, lang } = useSelector((state) => state.rootReducer);

  const copy = WIDGETS[lang];

  const center = sectionCenter || mapConfigs.center;
  let origin = String([center[1], center[0]].join());
  const [travelModel, setTravelMode] = useState(null);
  const [destination, setDestination] = useState(null);

  const [results, setResults] = useState({
    duration: null,
    distance: null,
    fare: null,
    polyline: null,
  });

  useEffect(() => {
    if (travelModel && destination) {
      setLoading(true);
      requestData(travelModel, origin, destination)
        .then((res) => {
          if (res.routes.length > 0) {
            setResults({
              distance: res.routes[0].legs[0].distance.text,
              duration: res.routes[0].legs[0].duration.text,
              fare: res.routes[0].fare?.text,
            });
            handleSource(res.routes[0].overview_polyline.points);

            setHasData(true);
          } else {
            setResults({
              duration: "Sem percurso",
              distance: null,
              fare: null,
            });
            setHasData(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (!destination) {
      removeLayerSource();
      mapRef && mapRef.flyTo({ ...mapConfigs });
      setResults({
        duration: null,
        distance: null,
        fare: null,
      });
    }
  }, [travelModel, destination]);

  const renderValues = () => {
    if (!results.duration) return "";
    return (
      <Grid container alignItems="center">
        <Grid item>{results.duration} </Grid>
        {results.distance && (
          <Grid item style={{ fontSize: 14, paddingLeft: 5 }}>
            ({results.distance})
          </Grid>
        )}
        {results.fare && <Grid item>{results.fare} </Grid>}
      </Grid>
    );
  };

  const handleSource = (polyline_results) => {
    removeLayerSource();

    if (!polyline_results) return;

    const polylineGeoJSON = polyline.toGeoJSON(polyline_results);

    const source = {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: { ...polylineGeoJSON },
      },
    };

    mapRef.addSource("routes", source);
    mapRef.addLayer({
      id: "routes",
      type: "line",
      source: "routes",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#009de0",
        "line-width": 8,
      },
    });

    var coordinates = source.data.geometry.coordinates;
    var bounds = coordinates.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new window.mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
    mapRef.fitBounds(bounds, {
      padding: 220,
    });
  };

  const removeLayerSource = () => {
    if (!mapRef) return;

    let mapLayer = mapRef.getLayer("routes");

    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      mapRef.removeLayer("routes").removeSource("routes");
    }
  };

  return (
    <div>
      {/* <button onClick={handleSource}>teste</button>
      <button onClick={removeSource}>remove</button> */}
      <ListItem style={{ padding: "0px 16px" }}>
        <Grid container>
          <Grid item xs={12}>
            <IconSelector travelModel={travelModel} setTravelMode={setTravelMode} />
          </Grid>
          <Grid item xs={12} className={classes.autoComplete}>
            <AutoComplete setValue={setDestination} />
          </Grid>
          <Grid item xs={12} className={classes.text}>
            {renderValues()}
          </Grid>
          <Grid item xs={12} className={classes.description}>
            {copy.distanceDescription}
          </Grid>
          <Grid item xs={12} className={classes.description}>
            {copy.source}: Google Directions API
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
};

export default WidgetDistance;
