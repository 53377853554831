import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { WIDGETS } from "../../data/copy";

const WidgetInterval = (props) => {
  const { lang, data, id, sectionName, section, loading } = props;

  const copy = WIDGETS[lang];
  const classes = useStyles();

  const { baseDistributionRange } = useSelector((state) => state.rootReducer);

  const renderLineOne = (mean) => (
    <Grid item xs={12} className={classes.lineOne}>
      {mean && parseInt(mean)} €
    </Grid>
  );

  const renderLineTwo = (avgPerSq) => (
    <Grid item xs={12} className={classes.lineTwo}>
      {avgPerSq.toLocaleString("en").replace(/,/g, " ")} €/m²
    </Grid>
  );

  const renderLineThree = () => (
    <Grid container className={classes.lineThree} justify="space-between" alignItems="center">
      <Grid item className={classes.lineThreeLeft}>
        {copy.minValue}
      </Grid>
      <svg className={classes.svg}>
        <polygon points="0,0 10,12 20,0" className={classes.polygon} />
      </svg>
      <Grid item className={classes.lineThreeRight}>
        {copy.maxValue}
      </Grid>
    </Grid>
  );

  const renderLineFour = () => {
    return (
      <Grid item xs={12}>
        <hr className={classes.lineFour} />
      </Grid>
    );
  };

  const renderLineFive = (min, max) => {
    return (
      <Grid container className={classes.lineFive}>
        <Grid item xs={4} className={classes.lineFiveLeft}>
          {min && min.toLocaleString("en").replace(/,/g, " ")} €
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} className={classes.lineFiveRight}>
          {max && max.toLocaleString("en").replace(/,/g, " ")} €
        </Grid>
      </Grid>
    );
  };

  const renderLineSix = (min, max) => {
    return (
      <Grid container className={classes.lineSix}>
        <Grid item xs={4} className={classes.lineSixLeft}>
          {min.perSq.toLocaleString("en").replace(/,/g, " ")} €/m²
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} className={classes.lineSixRight}>
          {max.perSq.toLocaleString("en").replace(/,/g, " ")} €/m²
        </Grid>
      </Grid>
    );
  };

  return data && data.mean > 0 ? (
    <Grid container className={classes.container} justify="center">
      {baseDistributionRange && (
        <Grid item xs={12}>
          {renderLineOne(data.mean)}
          {/* renderLineTwo(predict.avg.perSq) */}
          {renderLineThree()}
          {renderLineFour()}
          {renderLineFive(data.min, data.max)}
          {/* renderLineSix(predict.min, predict.max) */}
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container>
          {copy[id] && (
            <Grid item xs={12} className={classes.description}>
              {`${copy[id]}: ${sectionName} (${copy[section]})`}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container className={classes.priceIntervalContainer} justify="center">
      {!loading && (
        <Grid item xs={12}>
          <ListItem className={classes.showingContainer}>
            <ListItemText primary={copy.noResults} />
          </ListItem>
        </Grid>
      )}
    </Grid>
  );
};

export default WidgetInterval;
