import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setKeyValue } from "../../redux/rootReducer/actions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ukFlag from "../../assets/gb.svg";
import ptFlag from "../../assets/pt.svg";
import Avatar from "@material-ui/core/Avatar";
import { LANG_SWITCHER } from "../../data/copy";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "20px",
    height: "20px",
  },
  iconMedium: {
    width: "25px",
    height: "25px",
  },
  btn: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const LangSwitcher = (props) => {
  const { symbol, small } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.rootReducer);
  const copy = LANG_SWITCHER[lang];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLangSwitch = (lang) => () => {
    dispatch(setKeyValue("lang", lang));
    handleClose();
  };

  return (
    <div {...props}>
      <div onClick={handleClick}>
        <MenuItem
          onClick={handleClose}
          classes={{
            root: classes.btn,
          }}
        >
          <Avatar
            src={lang === "en" ? ukFlag : ptFlag}
            className={small ? classes.icon : classes.iconMedium}
          ></Avatar>
          {!symbol && (
            <ListItemText
              primary={copy[lang]}
              style={{ color: "rgb(82, 92, 102)", paddingLeft: 10 }}
            />
          )}
        </MenuItem>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLangSwitch("en")}>
          <Avatar
            src={ukFlag}
            className={small ? classes.icon : classes.iconMedium}
          ></Avatar>
          <ListItemText
            primary={copy.en}
            style={{ color: "rgb(82, 92, 102)", paddingLeft: 10 }}
          />
        </MenuItem>
        <MenuItem onClick={handleLangSwitch("pt")}>
          <Avatar
            src={ptFlag}
            className={small ? classes.icon : classes.iconMedium}
          ></Avatar>
          <ListItemText
            primary={copy.pt}
            style={{ color: "rgb(82, 92, 102)", paddingLeft: 10 }}
          />
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default LangSwitcher;
