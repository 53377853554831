const styles = (theme) => {
  return {
    container: {
      background: "#f2f6f9",
      // height: "100vh",
      position: "relative",
      overflowX: "hidden",
      overflowY: "hidden",
      [theme.breakpoints.only("xs")]: {
        padding: 0,
      },
    },
    innerContainer: {
      padding: 10,
      [theme.breakpoints.only("xs")]: {
        padding: 0,
      },
    },
    mapContainer: {
      flexGrow: 1,
      position: "relative",
      height: "calc(100vh - 20px)",
      [theme.breakpoints.up("md")]: {
        height: "calc(100vh - 20px)",
      },
      [theme.breakpoints.only("xs")]: {
        height: window.innerHeight,
      },
    },
    widgetContainer: {
      position: "relative",
      width: "380px",
      marginLeft: 10,
      borderRadius: 5,
      overflowY: "scroll",
      overflowX: "hidden",
      background: "white",
      [theme.breakpoints.up("md")]: {
        height: "calc(100vh - 20px)",
      },
      "&::-webkit-scrollbar": {
        width: "0.3em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.3)",
        outline: "1px solid slategrey",
        borderRadius: 500,
      },
    },
    widgetInnerContainer: {
      borderRadius: 50,
      height: "100%",
      background: "red",
    },
    widgetContainerMobile: {
      position: "absolute",
      bottom: "2.5%",
      right: 0,
      left: 0,
      zIndex: 99,
      height: "50vh",
    },
    widgetInnerContainerMobile: {
      height: "50vh",
      margin: "2.5%",
      borderRadius: 5,
      background: "white",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "0.3em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.3)",
        outline: "1px solid slategrey",
        borderRadius: 500,
      },
    },
  };
};

export default styles;
