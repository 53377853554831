import React from "react";
import { useSelector } from "react-redux";
import { MAP_OVERLAY } from "../../data/copy";
import RESI from "../../assets/resi_icon.png";
import UNI from "../../assets/uni_icon.png";
import styled from "styled-components";
import Selector from "./Selector";

const MapOverlay = () => {
  const { lang, layer } = useSelector((state) => state.rootReducer);
  const copy = MAP_OVERLAY[lang];

  const stops = ["#1a9850", "#fdae61", "#d73027"];
  const stopsLabel = [...copy.stops];

  if (layer === "a") {
    stops.reverse();
    //stopsLabel.reverse();
  }

  return (
    <Container>
      <InnerContainer bottom="12.5%">
        <div>
          <span className="title">
            <b>{copy.layer}:</b>
            <span className="title-layer">{copy[layer]}</span>
            <br></br>
          </span>
        </div>
        <Selector />
        {layer !== "n" && (
          <div>
            <span className="title">
              <b>{copy.map}</b>
              <br></br>
            </span>

            <span className="title">
              {copy[`${layer}Description`]}
              {/* {copy.values1} <br></br> {copy.values2} */}
              <br></br>
            </span>
            {stops.map((color, idx) => {
              return (
                <div key={idx}>
                  <Color color={color} />
                  <span className="title">{stopsLabel[idx]}</span>
                </div>
              );
            })}
            <div>
              <Color color="#484848" />
              <span className="title">{copy.undefined}</span>
            </div>
          </div>
        )}
      </InnerContainer>

      <InnerContainer>
        <div className="label-container">
          <img src={UNI} width="15px" alt="" />
          <span className="label">{copy.ies}</span>
        </div>
        <div className="label-container">
          <img src={RESI} width="15px" alt="" />
          <span className="label">{copy.residence}</span>
        </div>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div``;

const InnerContainer = styled.div`
  bottom: ${(p) => (p.bottom ? p.bottom : "3.7%")};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-right: 18px;
  border-radius: 3px;
  padding: 12.5px;
  min-width: 220px;
  overflow: auto;
  right: 0px;

  @media (max-width: 600px) {
    display: none;
  }

  .label-container {
    padding: 2.5px 0px;
    display: flex;
  }

  .label {
    padding-left: 10px;
    font-size: 12px;
    color: white;
  }

  .title {
    font-size: 12px;
    color: white;
  }

  .title-layer {
    padding-left: 5px;
  }
`;

const Color = styled.span`
  background: ${(p) => p.color};
  display: inline-block;
  margin-right: 10px;
  height: 10px;
  width: 10px;
`;

export default MapOverlay;
