import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TransitIcon from "@material-ui/icons/DirectionsBus";
import WalkingIcon from "@material-ui/icons/DirectionsRun";
import DrivingIcon from "@material-ui/icons/DirectionsCar";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  selected: {
    backgroundColor: "#009de0 !important",
    color: "white !important",
  },
}));

const IconSelector = (props) => {
  const { travelModel, setTravelMode } = props;

  const classes = useStyles();

  const handleTravelMode = (event, newTravelMode) => {
    if (!newTravelMode) return;
    setTravelMode(newTravelMode);
  };

  //driving (default) indicates standard driving directions using the road network.
  //walking requests walking directions via pedestrian paths & sidewalks (where available).
  //bicycling requests bicycling directions via bicycle paths & preferred streets (where available).
  //transit requests directions via public transit routes (where available).

  return (
    <Grid container>
      <ToggleButtonGroup
        value={travelModel}
        exclusive
        onChange={handleTravelMode}
        classes={{ root: classes.root }}
        size="small"
      >
        <ToggleButton value="transit" classes={{ root: classes.root, selected: classes.selected }}>
          <TransitIcon />
        </ToggleButton>
        <ToggleButton value="walking" classes={{ root: classes.root, selected: classes.selected }}>
          <WalkingIcon />
        </ToggleButton>
        <ToggleButton value="driving" classes={{ root: classes.root, selected: classes.selected }}>
          <DrivingIcon />
        </ToggleButton>
        {/*      <ToggleButton
          value="bicycling"
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <BicyclingIcon />
        </ToggleButton> */}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default IconSelector;
