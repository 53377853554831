import IconOne from "../assets/icon-1.svg";
import IconTwo from "../assets/icon-2.svg";
import IconThree from "../assets/icon-3.svg";
import IconFour from "../assets/icon-4.svg";
import IconFive from "../assets/icon-5.svg";
import IconSix from "../assets/icon-6.svg";
import IconSeven from "../assets/icon-7.svg";
import IconEight from "../assets/icon-8.svg";
import IconNine from "../assets/icon-9.svg";

export const DRAWER = {
  pt: {
    welcome: "Bem-vindo ao",
    title: "Observatório do Alojamento Estudantil",
    tagLine: "Uma nova forma de olhar o mercado de arrendamento para estudantes",
    tagLineTwo:
      "Simples e prática, esta plataforma permite-lhe acompanhar o mercado em tempo real. Escolha um Concelho e comece já a explorar.",
    selectCounty: "Seleccione um concelho:",
    startExplore: "ou começar a Explorar",
    knowMore: "Saber mais",
    information: "Informação",
  },
  en: {
    welcome: "Welcome to the",
    title: "Student Housing Observatory",
    tagLine: "A new approach to monitor the student housing market",
    tagLineTwo:
      "Simple and intuitive, this plataform allows you to track the market in real time. Choose a county and start exploring.",
    selectCounty: "Choose a county:",
    startExplore: "or start exploring",
    knowMore: "Know more",
    information: "Information",
  },
};

export const HOW_WORKS = {
  pt: {
    title: "Como funciona?",
    subtitle: "Uma solução inovadora que permite acompanhar as tendências de mercado",
    list: [
      {
        title: "Monitorização do mercado",
        subtitle: "Recolha e processamento diário de mais de 100 mil anúncios de imóveis.",
        icon: IconSeven,
      },
      {
        title: "Inteligência Artificial e Big Data",
        subtitle:
          "Tratamento de toda a informação recolhida para cálculo de todas as estatísticas apresentadas.",
        icon: IconNine,
      },
      {
        title: "Plataforma pública",
        subtitle: "Disponibilização da informação organizada por concelho e freguesia.",
        icon: IconEight,
      },
    ],
  },
  en: {
    title: "How does it work?",
    subtitle: "An innovative solution that identifies the main market trends",
    list: [
      {
        title: "Market monitoring",
        subtitle: "Daily collection and analysis of more than 100 thousand room listings.",
        icon: IconSeven,
      },
      {
        title: "Artificial Intelligence and Big Data",
        subtitle: "Metrics computed using all the processed information.",
        icon: IconNine,
      },
      {
        title: "Public platform",
        subtitle: "All the information available for all parishes and counties.",
        icon: IconEight,
      },
    ],
  },
};

export const PROTOCOLS = {
  pt: {
    title: "Protocolos de Alojamento",
    subtitle: "Protocolos assinados com pousadas da juventude, alojamentos locais e hotéis",
    text: `
      Para o ano letivo de 2020-2021, são disponibilizadas para
      estudantes do ensino superior, por todo o país, camas através de
      pousadas da juventude, alojamentos locais e hotéis.
      \n
      Para saber mais informações sobre estes protocolos pode
      aceder à página oficial da DGES`,
  },
  en: {
    title: "Housing protocols",
    subtitle: "Protocols with youth hostels, local accommodations and hotels",
    text: `
      For the 2020-2021 academic year, these protocols were signed so
      any higher education student can have access to rooms in
      youth hostels, local accommodations or hotels, throughtout the country
      \n
      You can know more about these protocols in the official page of the
      Directorate General for Higher Education (DGES)`,
  },
};

export const MOTIVATION = {
  pt: {
    title: "Missão",
    subtitle: "Apoiar e promover a transparência no mercado do alojamento estudantil",
    text: `
      O Observatório do Alojamento Estudantil é uma plataforma que pretende apoiar
      os estudantes do ensino superior na procura de alojamento disponibilizando,
      numa base diária, a oferta privada de quartos/apartamentos para estudantes,
      bem como lista de residências das Instituições de Ensino Superior.
      O Observatório surge por iniciativa da área governativa da ciência,
      tecnologia e ensino superior em parceria com a Alfredo Real Estate Analytics,
      uma startup portuguesa de Inteligência Artificial e Big Data focada no mercado
      imobiliário, e conta com a colaboração e feedback de diferentes intervenientes
      no mundo académico designadamente Associações de Estudantes e Serviços de Ação
      Social das Universidades e Institutos Politécnicos.
      \n
      Aliado ao propósito de apoiar os estudantes, esta ferramenta tem também como
      objetivo monitorizar a oferta de alojamento estudantil existente no mercado nacional,
      servindo de suporte à decisão e implementação de medidas que visem melhorar as
      condições de acesso dos estudantes deslocados ao alojamento.`,
  },
  en: {
    title: "Misson",
    subtitle: "Support and promote transparency in the student housing market",
    text: `
      The Student Housing Observatory is a platform that aims to support
      higher education students looking for accommodation by making available,
      on a daily basis, the private offer of rooms/apartments for students,
      as well as all the public residences of Higher Education Institutions.
      This Observatory was developed in partnership with the governmental area
      of ​​science, technology and higher education and Alfredo Real Estate Analytics,
      a Portuguese Artificial Intelligence and Big Data startup operating on the
      Real Estate Market, and has the collaboration and has the collaboration and
      input of different stakeholders in the academic world, namely Student Associations
      and various Social Services of multiple Higher Education Institutions.  
      \n
      Aligned with the purpose of supporting students, this tool also aims to
      monitor the student accommodation in the Portuguese market, serving as support to
      the decision and implementation of policies that aim to improve better housing
      conditions to displaced students.`,
  },
};

export const FEATURES = {
  pt: {
    title: "O Observatório",
    subtitle: "Acesso a um conjunto de estatísticas únicas em tempo real",
    list: [
      {
        title: "Valores de renda",
        subtitle: "Rendas praticadas em qualquer ponto do país",
        icon: IconOne,
      },
      {
        title: "Oferta diária de quartos",
        subtitle: "Número de quartos privados disponíveis em tempo real",
        icon: IconTwo,
      },
      {
        title: "Oferta pública de alojamento",
        subtitle: "Rede de Residências das Instituições de Ensino Superior",
        icon: IconThree,
      },
      {
        title: "Demografia",
        subtitle: "Evolução da população e número de estudantes por concelho",
        icon: IconFour,
      },
      {
        title: "Evolução do mercado",
        subtitle: "Variação dos preços no mercado",
        icon: IconSix,
      },
      {
        title: "Suporte público",
        subtitle: "Uma ferramento pública suportada pela comunidade",
        icon: IconFive,
      },
    ],
  },
  en: {
    title: "The Observatory",
    subtitle: "Access a unique set of statistics in real time",
    list: [
      {
        title: "Rent values",
        subtitle: "Know the rent values anywhere in the country",
        icon: IconOne,
      },
      {
        title: "Daily room offerings",
        subtitle: "Number of rooms for rent updated in real time",
        icon: IconTwo,
      },
      {
        title: "Public residences monitoring",
        subtitle: "Network of public residentes of all the Institutions",
        icon: IconThree,
      },
      {
        title: "Demography",
        subtitle: "Know the population and number of students trends for any county",
        icon: IconFour,
      },
      {
        title: "Market trends",
        subtitle: "How market prices have been evolving over itme",
        icon: IconSix,
      },
      {
        title: "Public purpose",
        subtitle: "A public tool supported by the community",
        icon: IconFive,
      },
    ],
  },
};

export const METHODOLOGY = {
  pt: {
    title: "Metodologia",
    subtitle: "Abordagem no cálculo das métricas",
    text: `
      A análise da oferta privada de alojamento é realizada através da
      recolha de fontes públicas de informação, designadamente portais
      imobiliários e sites de agências do setor, agregando dados de mais
      de 20 plataformas distintas. Para esta análise são monitorizados
      mais de 100 mil anúncios de quartos/apartamentos destinados a
      estudantes, sendo recolhida e tratada toda a informação do
      anúncio. Simultaneamente é apresentada a rede de residências
      públicas de estudantes, que engloba mais de 15 mil camas.
      \n
      O cálculo das estatísticas apresentadas é feito através
      de um processo encadeado de operações de modo a assegurar a melhor
      aproximação matemática dos valores reais de mercado. São
      identificadas, nomeadamente, as várias plataformas em que um
      determinado quarto está a ser promovido, identificando as
      duplicações de modo a não enviesar as estatísticas e contagens da
      oferta e são eliminados todos os dados incorretos ou que tenham
      valores atípicos (outliers). Todos estes procedimentos são
      executados diariamente de maneira a garantir que a informação está
      atualizada e representa, em tempo real, as condições de mercado.`,
  },
  en: {
    title: "Methodology",
    subtitle: "How the statistics are computed",
    text: `
      The private offer analysis is carried out through the collection of
      public sources of information, namely real estate agencies and listing
      websites, aggregating data from more 20 different platforms. This
      analysis monitors more than 100 thousand listings of rooms/apartments
      targeted to students. Simultaneously all the public residences are
      presented, which comprises more than 15 thousand beds.
      \n
      This statistics are computed through a chained process of operations in
      order to ensure the best mathematical approximation of real market values.
      A thorough outlier detection and removal is carried as well as a detailed
      scanning on all the various platforms on which a particular room is being
      promoted are identified, so no statistical bias is incurred in the process.
      All of these procedures are performed daily to ensure that the information is
      updated and represents, as accurately as possible, the real market conditions.`,
  },
};

export const SUPPORT = {
  pt: {
    title: "Apoio e suporte",
    subtitle: `
      Esta plataforma é feita para estudantes e promovida por entidades
      públicas e privadas. Sem quaisquer fins comerciais, serve apenas o
      propósito de trazer transparência ao mercado e suportar o processo
      de procura e escolha de alojamento, estando em constante
      desenvolvimento e atualização. Assim, todos os contributos são
      muito importantes para nós. Agradecemos todas as sugestões
      críticas ou comentários, que devem ser enviados para geral@alfredo.pt.`,
  },
  en: {
    title: "Support",
    subtitle: `
      This platform is made for students and promoted by public and private
      entities. Without any commercial purposes, its sole objective is to bring
      transparency to the market and support the process of searching and finding
      accommodation, while being in constant development. Thus, all contributions are
      very important to us. We welcome any suggestions criticisms or comments,
      which should be sent to geral@alfredo.pt.`,
  },
};

export const ALFREDO = {
  pt: {
    title: "Sobre a Alfredo Real Estate Analytics",
    subtitle: "Esta plataforma é feita para si e promovida por entidades públicas e privadas.",
    text: `
      Quantas decisões são hoje tomadas no mundo do mercado imobiliário
      com base em perceções? A verdade é que seguir apenas a intuição
      não chega, se o objetivo é tomar a decisão certa. Precisamos de
      factos. Mas como encontrá-los, no meio de tanta informação e
      desinformação disponível? A Alfredo foi criada para resolver este
      problema, com recurso aos últimos desenvolvimentos nas áreas de
      Inteligência Artificial e Big Data.
      \n
      A Alfredo Real Estate Analytics surge, assim, como
      resposta à falta de informação quantitativa de análise sobre o
      mercado imobiliário. A empresa centra-se na utilização de modelos
      de inteligência artificial e métodos de computação avançados para
      o desenvolvimento de soluções tecnológicas inovadores de análise e
      de apoio à decisão de diferentes stakeholders de mercado
      imobiliário, tais como agências imobiliárias, instituições
      bancárias, fundos de investimento e instituições públicas.`,
  },
  en: {
    title: "About Alfredo Real Estate Analytics",
    subtitle: "This platform was developed for you e is promoted by public and private entities.",
    text: `
      Today, how many decisions in the real estate market are made based on
      common sense? If the goal is to take the most informed decision, this is
      not enough. We need facts. But, in the midst of so much information and
      misinformation available, how do we find them? Alfredo was created to
      solve this problem, using the latest developments in the areas of
      Artificial Intelligence and Big Data.
      \n
      Alfredo Real Estate Analytics was created as a response to the lack
      of detailed quantitative information about the real estate market.
      The company focuses on the use of artificial intelligence and advanced
      computing methods to develop innovative technological solutions for analysis and
      support the decision-making process of different market stakeholders,
      namely real estate agencies, banks, investment funds and public institutions.`,
  },
};

export const MAP_OVERLAY = {
  pt: {
    legend: "Legenda:",
    ies: "Instituição de Ensino Superior",
    residence: "Residência",
    map: "Cores no mapa:",
    undefined: "não definido",
    stops: ["abaixo da média", "valor médio", "acima da média"],
    values1: "Variação relativa ao valor",
    values2: "médio por quarto no concelho",
    layer: "Escolher layer",
    a: "Tempo de absorção",
    p: "Gradiente de preços",
    n: "",
    pDescription: "Gradiente de preços dos quartos",
    aDescription: "Tempo médio no mercado (absorção)",
    nDescription: "",
  },
  en: {
    legend: "Legend:",
    ies: "Higher Education Institution",
    residence: "Residence",
    map: "Map colors:",
    undefined: "not defined",
    stops: ["below average", "average value", "above average"],
    values1: "Offset relative to the average",
    values2: "rent value for rooms in the county",
    layer: "Choose layer",
    a: "Time absortion",
    p: "Price gradient",
    n: "",
    pDescription: "Room price gradient by parish",
    aDescription: "Average time on market by parish",
    nDescription: "",
  },
};

export const MAP_CARDS = {
  pt: {
    residence: "Residência",
    ies: "Instituição de Ensino Superior",
    website: "Visitar site",
    residenceOwner: "Proprietário",
    totalBeds: "Total de camas",
    totalBedsCovid: "Total de camas pós-covid",
    address: "Morada",
    offer: "Número de quartos disponíveis para:",
    county: "Concelho",
    parish: "Freguesia",
  },
  en: {
    residence: "Residence",
    ies: "Higher Education Institution",
    website: "Website",
    residenceOwner: "Owner",
    totalBeds: "Total beds",
    totalBedsCovid: "Total beds w/ covid restrictions",
    address: "Address",
    offer: "Number of rooms available for:",
    county: "County",
    parish: "Parish",
  },
};

export const WIDGETS = {
  pt: {
    mean: "Média",
    baseDistribution: "Distribuição base",
    filteredDistribution: "Distribuição filtrada",
    expensesIncluded: "Despesas incluídas",
    furnished: "Quarto mobilado",
    equippedKitchen: "Cozinha equipada",
    doubleBed: "Cama dupla",
    cleaningService: "Serviço de limpeza",
    rooms: "Quartos",
    roomsPriceInterval: "Intervalo de preços",
    distribution: "Número de quartos em relação ao preço",
    offer: "Número de quartos disponíveis",
    offerTimeseries: "Evolução do número de quartos disponíveis",
    priceInterval: "Intervalo de valores de oferta diário",
    priceTimeseries: "Evolução do valor de arrendamento ao longo do tempo",
    studentTimeseries: "Evolução do número de estudantes",
    populationTimeseries: "Evolução da população residente",
    parishesList: "Freguesias",
    universitiesList: "Instituições de Ensino Superior",
    residencesList: "Residências",
    source: "Fonte",
    noResults: "Sem resultados",
    noRooms: "Não existem quartos para os filtros seleccionados",
    lowSample: "Pouca amostragem, pode condicionar resultados",
    minPrice: "Preço mínimo",
    avgPrice: "Preço médio",
    maxPrice: "Preço máximo",
    seeLess: "Ver menos",
    seeAll: "Ver todas",
    selectedItem: "ITEM SELECCIONADO",
    undo: "LIMPAR",
    searchOptions1: "Procurar",
    searchOptions2: "opções",
    minValue: "Valor mínimo",
    maxValue: "Valor máximo",
    county: "Concelho",
    parish: "Freguesia",
    active: "Vida Ativa",
    food: "Restaurantes",
    arts: "Artes e Entretenimento",
    health: "Saúde e Medicina",
    transport: "Transportes",
    nightlife: "Vida Noturna",
    rating: "Avaliações",
    nearby: "O que há por perto?",
    distance: "Distância às Instituições",
    distanceDescription: "Distância da freguesia/concelho à instituição",
    distanceAutocomplete: "Escolha uma instituição",
  },
  en: {
    mean: "Mean",
    baseDistribution: "Base distribution",
    filteredDistribution: "Filtered distribution",
    expensesIncluded: "Expenses included",
    furnished: "Furnished room",
    equippedKitchen: "Equipped kitchen",
    doubleBed: "Double bed",
    cleaningService: "Cleaning service",
    rooms: "Rooms",
    roomsPriceInterval: "Price interval",
    distribution: "Number of rooms in relation to price",
    offer: "Number of available rooms",
    offerTimeseries: "Number of rooms available over time",
    priceInterval: "Price range for available rents",
    priceTimeseries: "Rent prices evolution over time",
    studentTimeseries: "Number of students evolution over time",
    populationTimeseries: "Number of inhabitants evolution over time",
    parishesList: "Parishes",
    universitiesList: "Higher Educations Institutions",
    residencesList: "Residences",
    source: "Source",
    noResults: "No results",
    noRooms: "No rooms for the selected filters",
    lowSample: "Low data availability, results can be biased",
    minPrice: "Minimum price",
    avgPrice: "Average price",
    maxPrice: "Maximum price",
    seeLess: "Show fewer results",
    seeAll: "List all results",
    selectedItem: "SELECTED ITEM",
    undo: "UNDO SELECTION",
    searchOptions1: "Search",
    searchOptions2: "options",
    minValue: "Min. value",
    maxValue: "Max. value",
    county: "County",
    parish: "Parish",
    active: "Active life",
    food: "Restaurants",
    arts: "Arts and Entertainment",
    health: "Hospitals and Medicine",
    transport: "Public transportation",
    nightlife: "Nightlife",
    rating: "Rating",
    nearby: "What's nearby?",
    distance: "Distance to institutions",
    distanceDescription: "Distance from parish/county to institution",
    distanceAutocomplete: "Choose an institution",
  },
};

export const ADMIN_DIALOG = {
  pt: {
    adminArea: "Área administrativa",
    submit: "Submeter",
    restrict:
      "Acesso restrito a responsáveis nomeados das Instituições de Ensino Superior e/ou Residências. Se é um representante de uma das instuições e deseja atualizar os dados da mesma, por favor entre em contacto através do email geral@alfredo.pt",
    residence: "Residência",
    totalBeds: "Camas disponíveis",
    occupiedBeds: "Camas ocupadas",
    lastUpdate: "Última atualização",
    institutions: "Instituições",
    filterPlaceholder: "Procurar",
    actions: "Ações",
    labelRowsSelect: "items",
    labelRowsPerPage: "Items por página",
  },
  en: {
    adminArea: "Administrative area",
    submit: "Submit",
    restrict:
      "Restricted access to those responsible for the Higher Education Institutions and / or Residencies. If you are a representative of one of the institutions and wish to update the details of the institution, please contact us by email geral@alfredo.pt",
    residence: "Residence",
    totalBeds: "Beds available",
    occupiedBeds: "Beds occupied",
    lastUpdate: "Last update",
    institutions: "Institutions",
    filterPlaceholder: "Search",
    actions: "Actions",
    labelRowsSelect: "row",
    labelRowsPerPage: "Rows per page",
  },
};

export const LANG_SWITCHER = {
  pt: {
    pt: "Português (PT)",
    en: "Inglês (UK)",
  },
  en: {
    pt: "Portuguese (PT)",
    en: "English (UK)",
  },
};
