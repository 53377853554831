import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setLoading,
  setDistributionLoading,
  addSources,
  addMarkers,
  setAnimate,
} from "../../redux/rootReducer/actions";
import Observatory from "./Observatory";

const mapStateToProps = (state) => ({
  sectionSelected: state.rootReducer.sectionSelected,
  countyID: state.rootReducer.countyID,
  animate: state.rootReducer.animate,
  loading: state.rootReducer.loading,
  distributionLoading: state.rootReducer.distributionLoading,
  idx: state.rootReducer.idx,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoading,
      setDistributionLoading,
      setAnimate,
      addSources,
      addMarkers,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Observatory);
