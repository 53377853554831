import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "open sans",
    /*  fontFamily:
      "-apple-system,system-ui,BlinkMacSystemFont," +
      '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif', */
  },
  palette: {
    primary: {
      light: "#B3E5FC",
      main: "#112330",
      //dark: "#0288D1",
      // contrastText: "#fff",
    },
    /*   secondary: {
      light: "#000",
      main: "#000",
      //dark: "#000",
      contrastText: "#000",
    },
    error: {
      main: "#000",
      light: "#000",
      dark: "#000",
      contrastText: "#000",
    }, */
    divider: "#f9f9f9",
    /*  action: {
      active: "#000",
      hover: "#000",
      selected: "#000",
      disabled: "#000",
      disabledBackground: "#000",
    }, */
    text: {
      // primary: "#fff",
      /*  secondary: "#000",
      disabled: "#000",
      hint: "#000",
      icon: "#000", */
    },
    common: {
      /*   black: "#484848",
      white: "#fff", */
    },
    background: {
      /*     default: "#fff",
      paper: "#fff", */
    },
  },
  overrides: {
    MuiAutocomplete: {
      groupUl: {
        color: "#2e3c43",
      },
      input: {
        padding: "4px",
        border: "none",
      },
      listbox: {
        "&::-webkit-scrollbar": {
          width: "0.5em !important",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.3)",
          // outline: "1px solid slategrey",
          borderRadius: 500,
        },
      },
    },
    MuiIconButton: {
      root: {
        //color: "white",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgba(0, 0, 0, 0.12)",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "1000px !important",
        width: "1000px !important",
      },
    },
  },
});

export default theme;
