import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import AppBar from "../../components/AppBar";
import Mapbox from "../../components/Mapbox";
import MapOverlay from "../../components/MapOverlay";
import polyline from "@mapbox/polyline";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import WidgetFrame from "../../components/WidgetFrame";
import Skeleton from "@material-ui/lab/Skeleton";
import Requester from "../../helpers/Requester";
import LandingPage from "../../components/LandingPage";

class Observatory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      histograms: null,
    };

    this.requester = new Requester(process.env.REACT_APP_SERVER);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);

    document.readyState !== "complete"
      ? window.addEventListener("load", () => this.setState({ mounted: true }))
      : this.setState({ mounted: true });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { countyID, sectionSelected } = this.props;

    if (prevProps.countyID && countyID !== prevProps.countyID) {
      this.requestCountyData(countyID);
      this.requestDistributionData(countyID, sectionSelected);
    }

    if (sectionSelected !== prevProps.sectionSelected) {
      this.requestDistributionData(countyID, sectionSelected);
    }
  };

  averageGeolocation = (coords) => {
    var lat = [];
    var lng = [];

    coords.forEach((el) => {
      lat.push(el[1]);
      lng.push(el[0]);
    });

    var maxLat = lat.reduce(function (a, b) {
      return Math.max(a, b);
    });
    var maxLng = lng.reduce(function (a, b) {
      return Math.max(a, b);
    });

    var minLat = lat.reduce(function (a, b) {
      return Math.min(a, b);
    });
    var minLng = lng.reduce(function (a, b) {
      return Math.min(a, b);
    });

    return [minLng + (maxLng - minLng) / 2, minLat + (maxLat - minLat) / 2];
  };

  requestDistributionData = (countyID, sectionSelected) => {
    const { setDistributionLoading, setAnimate } = this.props;
    setDistributionLoading(true);

    let params = {};
    if (sectionSelected && typeof sectionSelected === "string" && sectionSelected.length > 5) {
      params = {
        parishID: sectionSelected.substring(sectionSelected.length - 6),
      };
    } else if (countyID && typeof countyID === "string") {
      params = {
        countyID: countyID,
      };
    }

    this.requester
      .get({
        urlPath: "price-distribution",
        params: params,
        options: { cancelKey: "priceDistribution" },
      })
      .then((res) => {
        setAnimate(true);
        this.setState(
          {
            histograms: res,
          },
          () => {
            setDistributionLoading(false);
          }
        );
      })
      .catch((error) => console.log(error));
  };

  /*
   * Query principles:
   * - Data key represents the data for the widgets having three options ('list', 'graph', 'indicator')
   * - Children represent data sources
   * - Every key in children which has a geojson key represents a data source (mapbox source)
   * - Every widget is connect to the data source by id (children[key])
   * - points represent markers
   */
  requestCountyData = (countyID) => {
    const { setLoading, addSources, addMarkers } = this.props;
    setLoading(true);

    this.requester
      .get({
        urlPath: `analytics/${countyID}`,
        options: { cancelKey: "countyData" },
      })
      .then((response) => {
        /*
         * Decode geojsons
         */
        Object.keys(response.sections).forEach((section) => {
          response.sections[section].geojson = response.sections[section].geojson.map(
            (geojson, idx) => {
              let coordinatesDecoded;
              if (geojson.geometry.type === "Polygon") {
                coordinatesDecoded = polyline.decode(geojson.geometry.coordinates[0]);
                coordinatesDecoded = [coordinatesDecoded.map((coords) => coords.reverse())];
              } else {
                coordinatesDecoded = geojson.geometry.coordinates.map((polygon) => [
                  polyline.decode(polygon[0]),
                ]);
                coordinatesDecoded = coordinatesDecoded.map((polygon) => [
                  polygon[0].map((coords) => coords.reverse()),
                ]);
              }

              return {
                ...geojson,
                geometry: {
                  ...geojson.geometry,
                  coordinates: coordinatesDecoded,
                },
                internal_id: geojson.id, // hack to make mapbox work with non string feature ids
                id: idx, // hack to make mapbox work with non string feature ids
              };
            }
          );
        });

        /**
         *
         * Parse Sources
         *
         */

        const sources = {};
        Object.keys(response.sections).forEach((key) => {
          response.sections[key].geojson.forEach((elm, idx) => {
            /**
             *
             * Javardice funcional para calcular centroids
             *
             */
            if (key === "parishes") {
              elm.properties.idx = idx;
              let geometryForCalculation;

              if (elm.geometry.coordinates.length > 1) {
                let entries = [];

                elm.geometry.coordinates.forEach((_elm) => {
                  entries = [...entries, ..._elm];
                });

                if (entries[0].length !== 2) {
                  entries = [].concat.apply([], entries);
                }

                geometryForCalculation = entries;
              } else geometryForCalculation = elm.geometry.coordinates[0];

              elm.properties.centroid = this.averageGeolocation(geometryForCalculation);
            }
          });

          sources[`${countyID}-${key}`] = {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features:
                key === "areas"
                  ? response.sections[key].geojson.filter((section) => section.properties.price)
                  : response.sections[key].geojson,
            },
          };
        });

        /**
         *
         * Parse Markers
         *
         */

        const markers = [];
        Object.keys(response.places).forEach((key) => {
          response.places[key].forEach((marker) => {
            const icon = {
              iconUrl:
                key === "residences"
                  ? "https://playground.s3.fr-par.scw.cloud/resi.png"
                  : key === "universities"
                  ? "https://playground.s3.fr-par.scw.cloud/uni.png"
                  : "",
              iconSize: [18, 18],
            };
            markers.push({
              type: "Feature",
              properties: { ...marker.properties, icon },
              geometry: marker.geometry,
            });
          });
        });

        /**
         * Add sources to mapbox
         * Add markers to mapbox
         * Add data to state
         */

        addSources(sources);
        addMarkers(markers);

        /* ############## TEMPORARY ############## */
        const countydata = response.data;
        const parishData = response.sections.parishes.data;

        const transformTimeseries = (data) => {
          const dataUpdated = [];
          const tmp = {};

          data.forEach((series) => {
            if (!series) return;
            if (
              series.id === "priceTimeseries" ||
              series.id === "minPriceTimeseries" ||
              series.id === "maxPriceTimeseries"
            ) {
              const key =
                series.id === "priceTimeseries"
                  ? "mean"
                  : series.id === "minPriceTimeseries"
                  ? "min"
                  : "max";
              tmp[key] = series;
            } else dataUpdated.push(series);
          });

          const multipleTimeseries = { ...tmp.mean };
          const tmpUpdated = {};

          if ("min" in tmp) tmpUpdated.min = tmp.min;
          if ("mean" in tmp) tmpUpdated.mean = tmp.mean;
          if ("max" in tmp) tmpUpdated.max = tmp.max;

          const dates = {};
          Object.keys(tmpUpdated).forEach(
            (key) =>
              tmp[key].data &&
              tmp[key].data.forEach((ts) =>
                ts.x in dates
                  ? (dates[ts.x] = { ...dates[ts.x], [key]: ts.y })
                  : (dates[ts.x] = { [key]: ts.y })
              )
          );

          multipleTimeseries.data = Object.keys(dates).map((ts) => ({
            x: ts,
            ...dates[ts],
          }));

          dataUpdated.splice(2, 0, multipleTimeseries);

          return dataUpdated;
        };

        const countyDataUpdated = transformTimeseries(countydata);
        const parishDataUpdated = parishData.map(transformTimeseries);

        /* ############## TEMPORARY ############## */

        this.setState(
          {
            countyData: countyDataUpdated,
            parishData: parishDataUpdated,
          },
          () => setLoading(false)
        );
      })
      .catch((err) => console.log(err));
  };

  resize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  render() {
    const {
      classes,
      sectionSelected,
      countyID,
      idx,
      loading,
      distributionLoading,
      animate,
      setAnimate,
    } = this.props;
    const { windowWidth, countyData, parishData, mounted } = this.state;

    const data =
      sectionSelected && parishData[idx]
        ? [...parishData[idx], ...countyData.slice(-3)]
        : countyID
        ? countyData
        : [];

    const renderLoading = () => {
      return (
        <Grid item xs={12} style={{ padding: 15 }}>
          <Grid container spacing={1} direction="column">
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={150} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={150} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={150} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={150} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={150} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
          </Grid>
        </Grid>
      );
    };

    const dataAugmented = data && [...data];
    if (dataAugmented) {
      dataAugmented.splice(
        2,
        0,
        {
          id: "distribution",
          type: "distribution",
          section: dataAugmented[0] && dataAugmented[0].section,
          sectionName: dataAugmented[0] && dataAugmented[0].sectionName,
          histograms: this.state.histograms,
        },
        {
          id: "distance",
          type: "distance",
          collapse: true,
        },
        {
          id: "nearby",
          type: "nearby",
          collapse: true,
        }
      );
    }

    const renderWidgets = (
      <Grid container className={classes.widgetContainer}>
        {/* renderNearbyWidget() */}
        {data &&
          data.length > 0 &&
          dataAugmented.map((widget, idx) => (
            <WidgetFrame
              mobile={false}
              widget={widget}
              loading={loading}
              distributionLoading={distributionLoading}
              animate={animate}
              setAnimate={setAnimate}
              key={idx}
            ></WidgetFrame>
          ))}
        {!data && renderLoading()}
      </Grid>
    );

    const renderWidgetsMobile = (
      <Grid container className={classes.widgetContainerMobile}>
        <Grid item xs={12} className={classes.widgetInnerContainerMobile}>
          {data &&
            data.length > 0 &&
            dataAugmented.map((widget, idx) => (
              <WidgetFrame
                mobile={true}
                widget={widget}
                loading={loading}
                distributionLoading={distributionLoading}
                animate={animate}
                setAnimate={setAnimate}
                key={idx}
              ></WidgetFrame>
            ))}
          {!data && renderLoading}
        </Grid>
      </Grid>
    );

    return (
      <Grid container className={classes.container}>
        <Grid container className={classes.innerContainer}>
          <Grid item sm={12} md className={classes.mapContainer}>
            <AppBar />
            {mounted ? (
              <Mapbox
                requestDistributionData={this.requestDistributionData}
                requestCountyData={this.requestCountyData}
              ></Mapbox>
            ) : (
              <div></div>
            )}
            <MapOverlay />
          </Grid>
          {windowWidth > 960 ? renderWidgets : renderWidgetsMobile}
        </Grid>
        <LandingPage />
      </Grid>
    );
  }
}

export default withStyles(styles)(Observatory);
