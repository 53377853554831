import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import SearchIcon from "@material-ui/icons/Search";
import { Grow } from "@material-ui/core";

import { HANDLE_CLICK_POINT, HANDLE_CLICK_SECTION } from "../../redux/rootReducer/constants";

import { useSelector, useDispatch } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { WIDGETS } from "../../data/copy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    fontSize: 11,
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
    },
  },
  listItemSelected: {
    fontSize: 11,
    textTransform: "uppercase",
    color: "rgba(0,0,0,0.12)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  progressRootSelected: {
    height: 5,
    margin: "5px 16px",
    borderRadius: 100,
    backgroundColor: lighten("#009de0", 0.5),
  },
  progressRoot: {
    height: 5,
    margin: "5px 16px",
    borderRadius: 100,
    backgroundColor: lighten("#eee", 0.5),
  },
  progressBarSelected: {
    borderRadius: 20,
    backgroundColor: "#008de0",
  },
  progressBar: {
    borderRadius: 20,
    backgroundColor: "rgba(0,0,0,0.12)",
  },
  listItemIcon: {
    minWidth: "20px!important",
  },
  searchContainer: {
    paddingTop: 25,
    "&:hover": {
      cursor: "pointer",
    },
  },
  showingContainer: {
    padding: "0px 16px 15px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchTextInput: {
    color: "#009de0",
  },
  searchText: {
    color: "#009de0",
    textTransform: "uppercase",
    fontSize: "10px !important",
  },
  searchSelectedText: {
    textTransform: "uppercase",
    fontSize: "10px !important",
  },
  searchCleanText: {
    color: "#009de0",
    textTransform: "uppercase",
    fontSize: "10px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIcon: {
    width: "15px",
    color: "#009de0",
  },
  description: {
    fontSize: 10,
    fontFamily: "open sans",
    color: "#2e3c43",
    padding: "0px 16px 15px",
  },
}));

const WidgetList = (props) => {
  const classes = useStyles();
  const { mapRepresentation, data, source } = props;
  const dispatch = useDispatch();
  const { pointSelected, sectionSelected, lang } = useSelector((state) => state.rootReducer);

  const copy = WIDGETS[lang];

  const [searching, toggleSearch] = useState(false);
  const handleToggle = () => {
    setSearchTerm("");
    toggleSearch(!searching);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (value) => setSearchTerm(value);

  React.useEffect(() => {
    const results = data.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, data]);

  const handleClick = (id, type, idx) => () => {
    switch (type) {
      case "point":
        return dispatch({
          type: HANDLE_CLICK_POINT,
          pointID: pointSelected !== id ? id : null,
          idx,
        });
      case "section":
        return dispatch({
          type: HANDLE_CLICK_SECTION,
          sectionID: sectionSelected !== id ? id : null,
          idx,
        });
      default:
        return;
    }
  };

  const renderLengendValue = (value) => {
    return value ? parseInt(value) : "";
  };

  const [rows, setRows] = useState(5);

  const filteredRows = 5;

  const selectedID = sectionSelected || pointSelected;

  // if selected and not showing in list, start array slice on selected index
  const index = searchResults.findIndex((x) => x.id === selectedID);
  const sliceStart = rows !== data.length && index !== -1 && index >= filteredRows ? index : 0;

  const ViewAll = () => {
    if (rows === data.length)
      return (
        <div className={classes.searchText} onClick={() => setRows(5)}>
          {copy.seeLess}
        </div>
      );
    return (
      <div className={classes.searchText} onClick={() => setRows(data.length)}>
        {copy.seeAll} ({data.length})
      </div>
    );
  };

  return (
    <div>
      <div>
        <Grow
          in={searching}
          style={{
            height: !searching ? 0 : "100%",
            transition: "all .2s ease",
          }}
        >
          <ListItem style={{ paddingBottom: 0 }}>
            <TextField
              autoFocus
              placeholder={"Procurar"}
              InputProps={{
                classes: { input: classes.searchTextInput },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#009de0", width: 20 }} />
                  </InputAdornment>
                ),
              }}
              value={searchTerm}
              onChange={(e) => handleChange(e.target.value)}
            />
          </ListItem>
        </Grow>
      </div>
      {searchResults.length !== 0 && (
        <div>
          <ListItem className={classes.showingContainer}>
            {
              <div className={classes.searchSelectedText}>
                {selectedID ? `1 ${copy.selectedItem}` : <ViewAll />}
              </div>
            }
            {selectedID && (
              <ListItemSecondaryAction onClick={handleClick(null, mapRepresentation)}>
                <div className={classes.searchCleanText}>{copy.undo}</div>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </div>
      )}
      {searchResults.slice(sliceStart, sliceStart + rows).map((item) => {
        const isSelected = item.id === pointSelected || item.id === sectionSelected;
        return (
          <div
            onClick={handleClick(item.id, mapRepresentation, item.idx)}
            key={item.id}
            className={
              (sectionSelected || pointSelected) && !isSelected
                ? classes.listItemSelected
                : classes.listItem
            }
          >
            <ListItem style={{ paddingBottom: 0 }}>
              <ListItemText
                disableTypography
                id={item.id}
                primary={item.label}
                style={{
                  maxWidth: item.legend && item.legend.length > 1 ? "55%" : null,
                }}
              />
              <ListItemSecondaryAction>
                {item.legend &&
                  item.legend.map((legend, idx) =>
                    idx !== 0 ? (
                      <React.Fragment key={idx}>
                        <ListItemText
                          key={idx}
                          disableTypography
                          style={{ display: "inline-flex", paddingLeft: 5 }}
                          primary="|"
                        />
                        <ListItemText
                          key={idx}
                          disableTypography
                          style={{ display: "inline-flex", paddingLeft: 5 }}
                          primary={renderLengendValue(legend.value) + " " + legend.units}
                        />
                      </React.Fragment>
                    ) : (
                      <ListItemText
                        key={idx}
                        disableTypography
                        style={{ display: "inline-flex", paddingLeft: 5 }}
                        primary={renderLengendValue(legend.value) + " " + legend.units}
                      />
                    )
                  )}
              </ListItemSecondaryAction>
            </ListItem>
            <LinearProgress
              classes={{
                root:
                  (sectionSelected || pointSelected) && !isSelected
                    ? classes.progressRoot
                    : classes.progressRootSelected,
                bar:
                  (sectionSelected || pointSelected) && !isSelected
                    ? classes.progressBar
                    : classes.progressBarSelected,
              }}
              variant="determinate"
              color="secondary"
              value={item.value * 100}
            />
          </div>
        );
      })}
      {searchResults.length === 0 && (
        <ListItem className={classes.showingContainer}>
          <ListItemText primary={copy.noResults} />
        </ListItem>
      )}
      {filteredRows && data.length > filteredRows && source && (
        <>
          <ListItem className={classes.searchContainer} onClick={() => handleToggle()}>
            {searching && <div className={classes.searchText}>Cancelar</div>}
            {!searching && (
              <React.Fragment>
                <ListItemIcon className={classes.listItemIcon}>
                  <SearchIcon classes={{ root: classes.searchIcon }} />
                </ListItemIcon>
                <div className={classes.searchText}>
                  {copy.searchOptions1} ({data.length} {copy.searchOptions2})
                </div>
              </React.Fragment>
            )}
          </ListItem>
          <Grid item xs={12} className={classes.description}>
            {copy.source}: {source}
          </Grid>
        </>
      )}
    </div>
  );
};

export default WidgetList;
