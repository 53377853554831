import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import moment from "moment";
import AdminTable from "./AdminTable";
import { ADMIN_DIALOG } from "../../data/copy";
import Requester from "../../helpers/Requester";
import { useSelector } from "react-redux";

const saveToken = (token) => {
  localStorage.setItem(
    "student-auth",
    JSON.stringify({
      token,
    })
  );
};

const loadToken = () => {
  const authStudent = JSON.parse(localStorage.getItem("student-auth"));

  if (authStudent) {
    const { token } = authStudent;
    return token;
  }
  return false;
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btn: {
    textTransform: "none",
    fontWeight: 700,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, loging, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: !loging && "white" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AdminDialog = (props) => {
  const classes = useStyles();
  const { lang } = useSelector((state) => state.rootReducer);
  const copy = ADMIN_DIALOG[lang];

  const alfredoAPIRequester = new Requester(process.env.REACT_APP_SERVER);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loging, setLoging] = useState(true);
  const [data, setData] = useState([]);

  const [fields, setFields] = useState({
    email: {
      id: "email",
      label: "Email",
      value: "",
      type: "email",
      error: false,
      helperText: "",
    },
    password: {
      id: "password",
      label: "Password",
      value: "",
      type: "password",
      error: false,
      helperText: "",
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const token = loadToken();
    if (token) {
      setLoging(false);
    }
    requestResidences();
  }, [open]);

  const requestResidences = () => {
    if (!open) return;
    setLoading(true);
    const token = loadToken();
    alfredoAPIRequester
      .get({
        urlPath: "residences",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        if (
          err.data.detail === "Not authenticated" ||
          err.data.detail === "userNotFound"
        ) {
          localStorage.clear();
          setLoging(true);
        }
      });
  };

  const handleLogin = () => {
    const encondedCredentials = btoa(
      `${fields.email.value}:${fields.password.value}`
    );
    alfredoAPIRequester
      .post({
        urlPath: "access-token",
        headers: {
          Authorization: `Basic ${encondedCredentials}`,
        },
      })
      .then((res) => {
        if (res.access_token) {
          saveToken(res.access_token);
          setLoging(false);
          requestResidences();
        }
      })
      .catch((err) => {
        if (err.data.detail === "invalidEmail") {
          setFields({
            ...fields,
            email: {
              ...fields.email,
              error: true,
              helperText: "Email inválido",
            },
          });
        }
        if (err.data.detail === "invalidPassword") {
          setFields({
            ...fields,
            password: {
              ...fields.password,
              error: true,
              helperText: "Password inválida",
            },
          });
        }
      });
  };

  const handleUpdate = (newData, oldData, resolve) => {
    const token = loadToken();
    alfredoAPIRequester
      .post({
        urlPath: "residence-occupancy",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          timestamp: new moment(),
          residenceID: newData.residenceID,
          occupiedBeds: newData.occupiedBeds,
          totalBeds: newData.totalBeds,
        },
      })
      .then((res) => {
        console.log(res);
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        newData.lastUpdate = new moment();
        dataUpdate[index] = newData;
        setData([...dataUpdate]);
        resolve();
      });
  };

  const handleChange = (id) => (e) => {
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value: e.target.value,
      },
    });
  };

  return (
    <div>
      <Fab
        onClick={handleClickOpen}
        variant="extended"
        size="small"
        style={{
          background: "transparent",
          border: "2px solid white",
          color: "white",
          textTransform: "capitalize",
          boxShadow: "none",
        }}
      >
        {loging ? "Login" : copy.adminArea}
      </Fab>

      <Dialog
        onClose={handleClose}
        maxWidth={loging ? "xs" : "xl"}
        PaperProps={{
          style: {
            backgroundColor: !loging && "#112330",
            color: !loging && "white",
          },
        }}
        className={classes.dialog}
        disableBackdropClick
        open={open}
      >
        {loging ? (
          <>
            <DialogTitle onClose={handleClose} loging={loging}>
              {copy.adminArea}
            </DialogTitle>
            <DialogContent>
              <Typography style={{ textAlign: "justify", fontSize: 12 }}>
                {copy.restrict}
              </Typography>
            </DialogContent>
            <DialogContent dividers>
              <Grid container spacing="2">
                {Object.keys(fields).map((key) => {
                  const field = fields[key];
                  return (
                    <Grid item xs={12} key={key}>
                      <TextField
                        autoFocus={field.id === "email"}
                        id={field.id}
                        onChange={handleChange(field.id)}
                        label={field.label}
                        type={field.type}
                        error={field.error}
                        helperText={field.helperText}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Grid container justify="center">
                <Button
                  fullWidth
                  //onClick={() => setLoging(false)}
                  onClick={handleLogin}
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                >
                  {copy.submit}
                </Button>
              </Grid>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle onClose={handleClose} style={{ width: "800px" }}>
              {copy.adminArea}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                {loading ? (
                  "Loading..."
                ) : (
                  <AdminTable
                    copy={copy}
                    data={data}
                    setData={setData}
                    handleUpdate={handleUpdate}
                  />
                )}
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AdminDialog;
