/* import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { handleClickSection, handleClickPoint, setKeyValue } from "../../redux/rootReducer/actions";
import Mapbox from "./Mapbox";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ handleClickSection, handleClickPoint, setKeyValue }, dispatch);
};

const mapStateToProps = (state) => {
  const {
    sectionSelected,
    pointSelected,
    mapConfigs,
    countyID,
    markers,
    sources,
    layer,
    lang,
    idx,
  } = state.rootReducer;

  return {
    sectionSelected,
    pointSelected,
    mapConfigs,
    countyID,
    markers,
    sources,
    layer,
    lang,
    idx,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapbox);
 */

import Mapbox from "./Mapbox";

export default Mapbox;
