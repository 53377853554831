import { connect } from "react-redux";
import WidgetInterval from "./WidgetInterval";

const mapStateToProps = (state) => {
  const { lang } = state.rootReducer;
  return {
    lang,
  };
};

export default connect(mapStateToProps)(WidgetInterval);
