import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";

import WidgetIndicator from "../WidgetIndicator";
import WidgetList from "../WidgetList";
import WidgetGraph from "../WidgetGraph";
import WidgetInterval from "../WidgetInterval";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten } from "@material-ui/core/styles";
import WidgetNearby from "../WidgetNearby/WidgetNearby";
import WidgetDistance from "../WidgetDistance";
import WidgetDistribution from "../WidgetDistribution";
import { WIDGETS } from "../../data/copy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "99%",
    backgroundColor: theme.palette.background.paper,
    padding: "5px 0",
    borderTop: "1px solid #f2f2f2",
    marginTop: "1px",
  },
  rootNoData: {
    width: "100%",
    maxWidth: "99%",
    backgroundColor: theme.palette.background.paper,
    padding: "5px 0",
    borderTop: "2px solid #BF616A",
  },
  title: {
    color: "#2e3c43",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  progressRoot: {
    height: 2,
    margin: "5px 0",
    borderRadius: 100,
    backgroundColor: lighten("#eee", 0.5),
    marginTop: "-7px",
  },
  progressBar: {
    borderRadius: 20,
    opacity: 0.5,
    backgroundColor: "#009de0",
  },
}));

const WidgetFrame = (props) => {
  const { mobile, widget, loading, distributionLoading, animate, setAnimate } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(widget.collapse === true ? false : true);
  // alow inner component to trigger loading and hasData
  const [_loading, setLoading] = useState(false);
  const [_hasData, setHasData] = useState(true);
  const { lang } = useSelector((state) => state.rootReducer);
  const copy = WIDGETS[lang];

  const handleClick = () => {
    setOpen(!open);
  };

  const renderWidgetContent = (type) => {
    switch (type) {
      case "indicator":
        return <WidgetIndicator {...widget} />;
      case "graph":
        return <WidgetGraph {...widget} />;
      case "list":
        return <WidgetList {...widget} />;
      case "interval":
        return <WidgetInterval {...widget} hasData={_hasData} />;
      case "distribution":
        return (
          <WidgetDistribution
            {...widget}
            loading={distributionLoading}
            mobile={mobile}
            animate={animate}
            setAnimate={setAnimate}
          />
        );
      case "nearby":
        return <WidgetNearby {...widget} setLoading={setLoading} setHasData={setHasData} />;
      case "distance":
        return <WidgetDistance {...widget} setLoading={setLoading} setHasData={setHasData} />;
      default:
        return;
    }
  };

  const hasData =
    widget.data ||
    (widget.data && widget.data.length > 0) ||
    (widget.data && Object.keys(widget.data).length > 0);

  return (
    <List
      subheader={
        (loading || _loading || (widget.type === "distribution" && distributionLoading)) && (
          <LinearProgress
            classes={{
              root: classes.progressRoot,
              bar: classes.progressBar,
            }}
          />
        )
      }
      className={hasData || _hasData ? classes.root : classes.rootNoData}
    >
      <ListItem button onClick={handleClick}>
        <ListItemText primary={copy[widget.id]} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {renderWidgetContent(widget.type)}
      </Collapse>
    </List>
  );
};

export default WidgetFrame;
